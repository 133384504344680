import Box from '@mui/material/Box';
import { gql } from 'urql';
import {
  LoadStage,
  useBatchFeedingTabQuery,
  useCreateBatchLoadMutation,
  useResetBatchLoadsMutation,
} from '@/web-types';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getBatchFeedingData } from '@/components/BatchFeeding/helpers';
import { formatLbs, formatNumber, formatPercentDoubleDigitPrecision } from '@/components/helpers/format';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { showConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal';
import { enqueueSnackbar } from 'notistack';
import TableHead from '@mui/material/TableHead';
import AddIcon from '@mui/icons-material/Add';
import LoadAndBuildCard from '@/components/Feeding/LoadAndBuildCard';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { DuplicateDropsEmptyView } from '@/components/CallFeedTab/DuplicateDropsEmptyView';
import { DevToolDateOverride } from '@/components/CallFeedTab';
import { DateSelect } from '@/components/CallFeedTab/DateSelect';
import { LoadingButton } from '@mui/lab';
import { REFETCH_INTERVAL } from '../Feeding/constants';
import { useNetworkConnection } from '../hooks/useNetworkConnection';
import { LoadingScreen } from '../LoadingScreen';
import { CircularProgress } from '@mui/material';
import type { localdate } from '@/common/models/types';
import { RationWithColorTag } from '@/components/Inventory/RationWithColorTag';

gql`
  query BatchFeedingTab($date: LocalDate) {
    getDropsByDate(date: $date, includeBatchDrops: true) {
      id
      type
      parentDropId
      date
      loadId
      penDropIndex
      loadDropIndex
      calledLbs
      fedLbs
      pen {
        id
        name
        position
      }
      ration {
        id
        name
        color
        currentRevision
        ingredients {
          id
          percentOfRationAsFed
          ingredient {
            id
            name
          }
        }
      }
    }

    getLoadsByDate(date: $date) {
      id
      date
      loadIndex
      name
      stage
      calledLbs
      loadedLbs
      fedLbs
      drops {
        id
      }
    }
  }

  mutation CreateBatchLoad($rationId: Int!, $date: LocalDate!) {
    createBatchLoad(rationId: $rationId, date: $date) {
      id
    }
  }

  mutation ResetBatchLoads($date: LocalDate!) {
    resetBatchLoads(date: $date)
  }
`;

export default function BatchFeedingTab({
  canDuplicateYesterdayDrops = false,
  selectedDate,
  dateOptions,
  onDateSelected,
  onSubmitSuccess,
}: Readonly<{
  canDuplicateYesterdayDrops?: boolean;
  selectedDate: localdate;
  dateOptions: {
    value: localdate;
    label: string;
  }[];
  onDateSelected: (date: localdate) => void;
  onSubmitSuccess?: () => void;
}>) {
  const isSuperAdmin = useIsSuperAdmin();
  const router = useRouter();
  const online = useNetworkConnection();
  const [expandedRationIds, setExpandedRationIds] = useState<number[]>([]);

  const [{ data, fetching, stale }, refetch] = useBatchFeedingTabQuery(
    useMemo(
      () => ({
        variables: { date: selectedDate },
        context: { additionalTypenames: ['Drop', 'Load'] },
      }),
      [selectedDate]
    )
  );
  const [{ fetching: creatingBatch }, createBatchLoad] = useCreateBatchLoadMutation();
  const [{ fetching: resettingBatchLoads }, resetBatchLoads] = useResetBatchLoadsMutation();

  const rations = getBatchFeedingData(data?.getDropsByDate ?? [], data?.getLoadsByDate ?? []);

  const toggleRation = (rationId: number) =>
    setExpandedRationIds((ids) => (ids.includes(rationId) ? ids.filter((id) => id !== rationId) : [...ids, rationId]));

  const createBatchFeedingLoad = async (rationId: number) => {
    const { data } = await createBatchLoad({ date: selectedDate, rationId });
    await router.push(`/dashboard/feeding/batch-load/${data?.createBatchLoad.id}`);
  };

  const reset = async () => {
    await showConfirmationModal({
      title: 'Reset Batch Feeding',
      message: (
        <Typography>
          Are you sure you want to reset all batch loads for today?
          <br /> This will remove all loads and drops for today and cannot be undone.
        </Typography>
      ),
      confirmButton: 'Reset',
      onConfirm: async () => {
        const { error } = await resetBatchLoads({ date: selectedDate });
        if (!error) {
          enqueueSnackbar('Batch Feeding Reset complete', { variant: 'success' });
          refetch();
        }
      },
    });
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (online && !fetching && !creatingBatch && !resettingBatchLoads) {
        refetch();
      }
    }, REFETCH_INTERVAL);
    return () => clearInterval(timerId);
  }, [creatingBatch, fetching, online, refetch, resettingBatchLoads]);

  const isTodaySelected = useMemo(() => selectedDate === dateOptions[0].value, [dateOptions, selectedDate]);
  const isDropsEmptyTomorrow = useMemo(
    () => !isTodaySelected && !data?.getDropsByDate?.length,
    [data?.getDropsByDate?.length, isTodaySelected]
  );

  if (isTodaySelected && canDuplicateYesterdayDrops) {
    const fromDate = dayjs(dateOptions[0].value).subtract(1, 'day').format('YYYY-MM-DD');
    const toDate = dateOptions[0].value;
    return (
      <>
        <DuplicateDropsEmptyView
          isTodaySelected={isTodaySelected}
          fromDate={fromDate}
          toDate={toDate}
          onSuccess={onSubmitSuccess}
        />
        {isSuperAdmin && <DevToolDateOverride />}
      </>
    );
  }

  if (fetching) {
    return <LoadingScreen />;
  }

  return (
    <Box p={3}>
      {stale && <CircularProgress size={20} sx={{ mx: 1, position: 'absolute', left: 5, top: 10 }} />}

      {isSuperAdmin && (
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <DateSelect
            value={selectedDate}
            onChange={onDateSelected}
            options={dateOptions}
            iconTooltip="This toggle allows you to build loads for today or tomorrow"
          />

          {data?.getLoadsByDate?.length! > 0 && <Button onClick={() => reset()}>Admin: Reset</Button>}
        </Stack>
      )}

      {isDropsEmptyTomorrow ? (
        <DuplicateDropsEmptyView
          fromDate={dateOptions[0].value}
          toDate={dateOptions[1].value}
          onSuccess={onSubmitSuccess}
        />
      ) : (
        <Box>
          {rations.map((ration) => {
            const isExpanded = expandedRationIds.includes(ration.id!);
            return (
              <Box key={`ration-${ration.id}`}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => toggleRation(ration.id!)}
                  sx={{ cursor: 'pointer', background: (theme) => theme.palette.grey[100], padding: 1 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  >
                    {isExpanded ? (
                      <KeyboardArrowDownIcon data-testid="ration-collapse-icon" />
                    ) : (
                      <KeyboardArrowRightIcon data-testid="ration-expand-icon" />
                    )}
                    <Box display="flex">
                      <RationWithColorTag ration={ration} nameSx={{ fontWeight: 600 }} />
                      <Typography display="inline" color={(theme) => theme.palette.common.black} ml={2}>
                        {formatNumber(ration.pens.length)} Pens
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap={3}>
                    <Typography sx={{ whiteSpace: 'nowrap' }}>
                      Called: <b>{formatLbs(ration.calledLbs)}</b>
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap' }}>
                      Remaining: <b>{formatLbs(ration.absRemainingLbs)}</b>
                    </Typography>
                    <Typography sx={{ whiteSpace: 'nowrap' }}>
                      Fed: <b>{formatLbs(ration.fedLbs)}</b>
                    </Typography>
                  </Box>
                </Box>

                {isExpanded && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width={250}>Pen</TableCell>
                          <TableCell width={100}>Drops</TableCell>
                          <TableCell>Progress</TableCell>
                          <TableCell width={100}>Called</TableCell>
                          <TableCell width={100}>Remaining</TableCell>
                          <TableCell width={100}>Fed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ration.pens.map((pen) => (
                          <TableRow key={`pen-${pen.id}`}>
                            <TableCell>{pen.name}</TableCell>
                            <TableCell data-testid="cell-num-drops">{formatNumber(pen.dropsCount)}</TableCell>
                            <TableCell>
                              <ProgressBar
                                data-testid="cell-progress"
                                value={pen.completedPct * 100}
                                text={formatPercentDoubleDigitPrecision(pen.completedPct)}
                              />
                            </TableCell>
                            <TableCell data-testid="cell-called">{formatLbs(pen.calledLbs)}</TableCell>
                            <TableCell data-testid="cell-remaining">{formatLbs(pen.remainingLbs)}</TableCell>
                            <TableCell data-testid="cell-fed">{formatLbs(pen.fedLbs)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Box display="flex" gap={1} alignItems="center" py={3}>
                  <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
                    {ration.loads.map((load, index) => (
                      <Grid item xs={4} md={3} key={load.id}>
                        <LoadAndBuildCard
                          loadName={load.name}
                          loadedLbs={load.loadedLbs ?? 0}
                          calledLbs={load.calledLbs ?? 0}
                          fedLbs={load.fedLbs ?? 0}
                          penNames={load.pens.map((p) => p.name ?? '')}
                          stage={load.stage!}
                          onClick={() =>
                            router.push(
                              load.stage === LoadStage.Completed
                                ? `/dashboard/feeding/loads-feeds/${load.id}`
                                : `/dashboard/feeding/batch-load/${load.id}`
                            )
                          }
                        />
                      </Grid>
                    ))}
                    <Grid item xs={4} md={3} display="flex" alignItems="center">
                      <LoadingButton
                        startIcon={<AddIcon />}
                        variant="outlined"
                        onClick={() => createBatchFeedingLoad(ration.id!)}
                        loading={creatingBatch}
                      >
                        Add Load
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
