import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { Controller, ControllerProps, Path, UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type ControlledRadioGroupFieldProps<TFieldValues extends FieldValues = FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<RadioGroupProps, 'name'> & {
      name: Path<TFieldValues>;
      rules?: ControllerProps['rules'];
    };

export default function ControlledRadioGroupField<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules = {},
  children,
  ...componentProps
}: ControlledRadioGroupFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <RadioGroup {...componentProps} {...field}>
          {children}
        </RadioGroup>
      )}
    />
  );
}
