import { PenCallAsFedByDropPlanDay, CallFeedDropIdAndActual, rationOrLast } from '.';

// The functions for interpreting/modifying a per drop plan

export function calcPerDropCalls(
  plan: PenCallAsFedByDropPlanDay,
  drops: readonly CallFeedDropIdAndActual[],
  currentHead: number
) {
  const args = plan.dropCallAmounts.map((afLbs, i) => {
    const index = plan.mapIndexes?.[i];
    const d = drops[index];
    return {
      dropId: d?.dropId,
      // TODO: decide which of these is correct, can plan and actual differ for non-batch? if so, which is correct?
      //afLbs: feedingMethod === feedingMethod.Batch ? afLbs : a?.afLbsCalled ?? afLbs,
      afLbs: afLbs,
      head: d?.actual?.head ?? currentHead,
      rationId: d?.actual?.rationId ?? rationOrLast(plan.rationIds, i),
    };
  });
  return args.map(({ dropId, afLbs, head, rationId }) => ({
    dropId,
    rationId,
    head,
    amount: { afLbs, toPlan: { m: 1, b: 0, c: 0 } },
  }));
}
