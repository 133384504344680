import { IncrementalNumberInput } from './IncrementalNumberInput';
import { NumberInputProps } from './NumberInput';
import { useCallback } from 'react';
import { Input } from '@/components/types';
import { CallAmount } from '@/components/CallFeed/callPlan';

type CallAmountInputProps = Omit<NumberInputProps, 'value' | 'onChange' | 'decimals'> &
  Input<CallAmount> & { increment: number };
export const CallAmountInput: React.FC<CallAmountInputProps> = ({ value, onChange, increment, ...props }) => {
  const onCallAmountChange = useCallback(
    (v: number, params: unknown) => onChange({ ...value, afLbs: v }, params),
    [value, onChange]
  );
  return (
    <IncrementalNumberInput
      increment={increment}
      decimals={0}
      value={value.afLbs}
      onChange={onCallAmountChange}
      {...props}
    />
  );
};
