import { Input } from '@/components/types';
import TextField from '@mui/material/TextField';
import { useMemo, useState } from 'react';
import { InputBaseComponentProps, SxProps } from '@mui/material';

export type NumberFieldValue = Input<number> & {
  decimals?: number;
  sx?: SxProps;
};

export type NumberInputProps = NumberFieldValue & { inputProps?: InputBaseComponentProps };

export const NumberInput = ({ params, value, onChange, disabled, decimals = 2, sx, inputProps }: NumberInputProps) => {
  // Controlled number fields are tricky because the value is a number
  // but the editable representation is a string
  // and there is more than one string representation of a given number
  // 5 can also be represented as "5" or "5." or "5.0", etc.
  // But no matter what you send upstream through onChange, it will always return just the number
  // So to retain intermediate state, you must keep track of the string representation
  // When the control is focused, we'll use the string representation
  // But when it loses focus we'll revert to the canonical representation of the number
  // TODO: To do this correctly, we also need to reject key strokes that would result in a non-number
  // TODO: allow formatting that adds commas
  // TODO: add re-formatting on keystroke
  const toString = useMemo(() => (n: number) => n.toFixed(decimals), [decimals]);
  const toNumber = useMemo(() => (s: string) => Number(s), []);
  const [state, setState] = useState<{ value: string; focused: boolean }>({ value: toString(value), focused: false });
  return (
    <TextField
      inputProps={inputProps}
      disabled={disabled}
      value={state.focused ? state.value : toString(value)}
      sx={sx}
      onFocus={() => {
        setState((prev) => ({ value: toString(value), focused: true }));
      }}
      onBlur={() => {
        setState((prev) => ({ value: toString(value), focused: false }));
      }}
      onChange={(e) => {
        setState((prev) => ({ value: e.target.value, focused: prev.focused }));
        // this round trip ensures that the value that is sent upstream via onChange is the same as we'll see after a blur
        const newValue = toNumber(toString(toNumber(e.target.value)));
        if (newValue !== toNumber(state.value) && !isNaN(newValue)) {
          onChange(newValue, params);
        }
      }}
    />
  );
};
