import Box from '@mui/material/Box';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { IconButton } from '@mui/material';
import { showObservationsModal } from '@/components/CallFeed/ObservationsModal';
import { BunkScoreValue } from '@/web-types';
import { bunkScoreLabel } from '@/common/dictionaries/bunkScore';
import { Input } from '../types';

type ObservationsBoxProps = {
  bunkScore: Input<BunkScoreValue | undefined>;
};
export const ObservationsBox: React.FC<ObservationsBoxProps> = ({ bunkScore }) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        background: 'white',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
        padding: `3px 10px`,
        gap: 2,
        alignItems: 'center',
        mb: 2,
      })}
    >
      <Box display="flex" flex={1} gap={2}>
        <Box>Bunk Score: {bunkScore.value ? bunkScoreLabel(bunkScore.value) : '--'}</Box>
        {/*
        <Box>Aggression --</Box>
        <Box>Notes --</Box>
        */}
      </Box>
      <IconButton
        onClick={() =>
          // TODO: Propagate real value once we have it
          showObservationsModal(bunkScore)
        }
        data-testid="bunk-score-edit-button"
      >
        <ModeOutlinedIcon />
      </IconButton>
    </Box>
  );
};
