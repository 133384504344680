import { PlanCallUnit } from '@/web-types';
import { sum, sumBy } from 'lodash';

// The purpose of this is to remove superfluous precision from the distributions that are far beyond what we need for call feed
// and are at the edge of what is representable by javascript
// this implementation preserves the total by adjusting the last number to account for any accumulation of rounding the others in the same direction
function rounded(numbers: number[]): number[] {
  if (numbers.length === 0) return [];
  const precision = Math.pow(10, -14);
  const originalSum = numbers.reduce((acc, num) => acc + num, 0);
  const roundedExceptLast = numbers.slice(0, -1).map((num) => Math.round(num / precision) * precision);
  const sumRoundedExceptLast = roundedExceptLast.reduce((acc, num) => acc + num, 0);
  const adjustedLast = originalSum - sumRoundedExceptLast;
  const roundedLast = Math.round(adjustedLast / precision) * precision;
  return [...roundedExceptLast, roundedLast];
}

/**
 * Returns the distribution of the values such that
 * 1. The proportions are preserved, and
 * 2. The positive numbers sum to 1
 */
export function normalizeDistribution(numbers: readonly number[]): readonly number[] {
  // for all zeroes, just return an even distribution
  if (numbers.every((n) => n === 0)) return rounded(numbers.map(() => 1 / numbers.length));
  // get the sum of the positive numbers
  const total = sum(numbers.filter((n) => n >= 0));
  // do something sane if all numbers are non-positive
  if (total === 0) return rounded(normalizeDistribution(numbers.map((n) => -n)).map((n) => -n));
  // return the proportions of all the positive numbers
  return rounded(numbers.map((n) => n / total));
}

/**
 * Redistributes values such that
 * 1. The sum of the result is always equal to the sum of the original values
 * 2. The elements corresponding to the defined forced values equal those forced values
 * 3. The length of the result is always equal to the length of the original values or with 1 remainder element
 * 4. All elements are non-negative except the remainder element
 * 5. The remainder is always non-zero
 * 6. The absolute value of the remainder is as small as possible
 *    (all unforced elements are already zero or there are no unforce elements)
 * 7. Will return the original values array if all are unforced
 */
export function redistributeAroundForced(
  values: readonly number[],
  force: readonly (number | undefined)[]
): readonly number[] {
  if (values.every((v, i) => force[i] === undefined || force[i] === v)) {
    return values;
  }
  if (values.every((_, i) => force[i] !== undefined)) {
    const remainder = sum(values) - sum(force);
    const v = values.map((_, i) => force[i]!);
    return Math.abs(remainder) > 0.001 ? [...v, remainder] : v;
  }
  const target = sum(values);
  const amountForced = sumBy(force, (f) => (f !== undefined ? f : 0));
  const toDistribute = target - amountForced;
  const unforced = sum(values.map((v, i) => (force[i] === undefined ? v : 0)));
  const distribution = normalizeDistribution(
    values.map((v, i) => (force[i] !== undefined ? 0 : unforced ? v / unforced : 1))
  );
  const distributed = distribution.map((d, i) => force[i] ?? d * toDistribute);
  const negativeRemainder = sum(distributed.filter((n) => n < 0));
  return negativeRemainder < 0 ? [...distributed.map((d) => (d < 0 ? 0 : d)), negativeRemainder] : distributed;
}

/** Whether a PlanCallUnit is per head */
export function isPerHead(planCallUnit: PlanCallUnit) {
  return planCallUnit === PlanCallUnit.AsFedLbsPerHead || planCallUnit === PlanCallUnit.DmiLbsPerHead;
}

/** Whether a PlanCallUnit specifies dry matter intake */
export function isDmi(planCallUnit: PlanCallUnit) {
  return planCallUnit === PlanCallUnit.DmiLbsPerHead;
}

function finiteOrZero(x: number) {
  return isFinite(x) ? x : 0;
}

/**
 * Get a conversion factor for converting from one plan unit to another
 * @param fromUnit The unit to convert from
 * @param toUnit The unit to conver to
 * @param head The head count to use if needed
 * @param dryMatterRatio The dry matter ratio to use if needed
 * @returns
 */
export function planUnitConversionFactor(
  fromUnit: PlanCallUnit,
  toUnit: PlanCallUnit,
  head: number,
  dryMatterRatio: number
): number {
  const isFromPerHead = isPerHead(fromUnit);
  const isToPerHead = isPerHead(toUnit);
  const isFromDmi = isDmi(fromUnit);
  const isToDmi = isDmi(toUnit);
  const headFactor = isFromPerHead === isToPerHead ? 1 : isToPerHead ? 1 / head : head;
  const dmRatioFactor = isFromDmi === isToDmi ? 1 : isToDmi ? dryMatterRatio : 1 / dryMatterRatio;
  // infinite, here, means that a divisor was zero
  // this can happen, for example, if you were feeding as-fed an empty pen (0 head) and want to switch to per-head
  // when this happens, the most reasonable default behavior is to assume 0 per head.
  // it could happen with dmi only if you had a ration of 0% dry matter (water), but we'll handle that the same way
  return finiteOrZero(headFactor) * finiteOrZero(dmRatioFactor);
}

/**
 * Convert an amount from one plan unit to another
 * @param fromUnit The unit to convert from
 * @param toUnit The unit to convert to
 * @param amount The amount to convert
 * @param head The head count to use if needed
 * @param dryMatterRatio The dry matter ratio to use if needed
 * @returns
 */
export function planUnitConvert(
  amount: number,
  fromUnit: PlanCallUnit,
  toUnit: PlanCallUnit,
  head: number,
  dryMatterRatio: number
) {
  return amount * planUnitConversionFactor(fromUnit, toUnit, head, dryMatterRatio);
}
