/**
 * Signals code that should be unreachable.
 * If this code is reached in production, it will throw an error with the associated message.
 *
 * One use case of this is to allow easily including a message if you want to do a non-null assertion
 * ```
 *   const value = maybeValue ?? assertImpossible("maybeValue must be defined");
 *   //            ^^^^^^^^^^... this is type: number | undefined
 *   //    ^^^^^................ this is type: number
 * ```
 *
 * The benefit of this over just `!.` is that this can show a useful error message
 * and can be used in any situation of unreachable code, not just nullability
 *
 * The benefit of this over just `throw Error(...)` is that it signals the expectation of unreachability
 * and can be used in expressions where `throw` cannot be used
 */
export function assertImpossible(message: any): never {
  // using ErrorFromWrapper makes the location of the error get reported as where the assertImpossible was called and not here
  throw new ErrorFromWrapper(`Assertion failure: ${message}`, assertImpossible);
}

// This error is designed to be used when throwing from a wrapper function
// when it's not useful for that wrapper function to be on the stack trace
class ErrorFromWrapper extends Error {
  constructor(message: string, ignoreFunctionName: Function) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorFromWrapper);
    }
    this.stack = this.stack
      ?.split('\n')
      .filter((line) => !line.includes(ignoreFunctionName.name))
      .join('\n');
  }
}
