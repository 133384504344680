import { Box, BoxProps, Grid, styled } from '@mui/material';
import { IncrementalNumberInput } from '@/components/CallFeed/controls/IncrementalNumberInput';
import { CallFeedDayUiState } from '@/components/CallFeed/hooks/useCallFeedUiState';
import { formatNumber, formatPercentDoubleDigitPrecision } from '@/common/utils/format';

const CellTitle = styled(Grid)({
  fontWeight: 'bold',
});
const CellValue = styled(Grid)({
  textAlign: 'center',
});

export const FeedPlanBox = ({
  currentDay,
  day,
  increments,
}: {
  currentDay: CallFeedDayUiState;
  day: string;
  increments: { dmiPerHead: number; afPerHead: number };
}) => {
  return (
    <Grid container rowGap={2} sx={{ width: '100%', alignItems: 'center' }}>
      {day === 'today' ? (
        <>
          <CellTitle item xs={3}>
            AF
          </CellTitle>
          <CellValue item xs={4} data-testid="as-fed-current">
            {formatNumber(currentDay.afLbs.value)}
          </CellValue>
          <CellValue item xs={3} data-testid="as-fed-difference">
            <DailyChange value={currentDay.afLbsDayDifference} />
          </CellValue>
          <Grid item xs={2} data-testid="as-fed-previous-day">
            {formatNumber(currentDay.afLbsPreviousDay)}
          </Grid>

          <CellTitle item xs={3}>
            AF/Head
          </CellTitle>
          <CellValue item xs={4}>
            <IncrementalNumberInput
              increment={increments.afPerHead}
              {...currentDay.afLbsPerHead}
              inputProps={{ 'data-testid': 'af-per-head-current' }}
            />
          </CellValue>
          <CellValue item xs={3} data-testid="af-per-head-difference">
            <DailyChange value={currentDay.afLbsPerHeadDayDifference} />
          </CellValue>
          <Grid item xs={2} data-testid="af-per-head-previous-day">
            {formatNumber(currentDay.afLbsPerHeadPreviousDay)}
          </Grid>

          <CellTitle item xs={3}>
            DMI/Head
          </CellTitle>
          <CellValue item xs={4}>
            <IncrementalNumberInput
              increment={increments.dmiPerHead}
              {...currentDay.dmiLbsPerHead}
              inputProps={{ 'data-testid': 'dmi-per-head-current' }}
            />
          </CellValue>
          <CellValue item xs={3} data-testid="dmi-per-head-difference">
            <DailyChange value={currentDay.dmiLbsPerHeadDayDifference} />
          </CellValue>
          <Grid item xs={2} data-testid="dmi-per-head-previous-day">
            {formatNumber(currentDay.dmiLbsPerHeadPreviousDay)}
          </Grid>

          <CellTitle item xs={3}>
            DM % BW
          </CellTitle>
          <CellValue item xs={4} data-testid="dm-percent-bw-current">
            {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBW!)}
          </CellValue>
          <CellValue item xs={3} data-testid="dm-percent-bw-difference">
            <DailyChange value={currentDay.dmAsPercentOfBWDayDifference} usePercentFormatted />
          </CellValue>
          <Grid item xs={2} data-testid="dm-percent-bw-previous-day">
            {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBWPreviousDay!)}
          </Grid>
        </>
      ) : (
        <>
          <CellTitle item xs={3}>
            AF
          </CellTitle>
          <CellValue item xs={5} data-testid="as-fed-current">
            {formatNumber(currentDay.afLbs.value)}
          </CellValue>
          <Grid item xs={4} />

          <CellTitle item xs={3}>
            AF/Head
          </CellTitle>
          <CellValue item xs={5}>
            <IncrementalNumberInput
              increment={increments.afPerHead}
              {...currentDay.afLbsPerHead}
              inputProps={{ 'data-testid': 'af-per-head-current' }}
            />
          </CellValue>
          <Grid item xs={4} />

          <CellTitle item xs={3}>
            DMI/Head
          </CellTitle>
          <CellValue item xs={5}>
            <IncrementalNumberInput
              increment={increments.dmiPerHead}
              {...currentDay.dmiLbsPerHead}
              inputProps={{ 'data-testid': 'dmi-per-head-current' }}
            />
          </CellValue>
          <Grid item xs={4} />

          <CellTitle item xs={3}>
            DM % BW
          </CellTitle>
          <CellValue item xs={5} data-testid="dm-percent-bw-current">
            {formatPercentDoubleDigitPrecision(currentDay.dmAsPercentOfBW!)}
          </CellValue>
          <Grid item xs={4} />
        </>
      )}
    </Grid>
  );
};

interface BoxPropsWithDataTestId extends BoxProps {
  'data-testid'?: string;
}

const DailyChange = ({
  value,
  usePercentFormatted,
  ...boxProps
}: {
  value: number;
  usePercentFormatted?: boolean;
  boxProps?: BoxPropsWithDataTestId;
}) => {
  const formattedValue = usePercentFormatted ? formatPercentDoubleDigitPrecision(value) : formatNumber(value);
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: value >= 0 ? 'rgba(0, 200, 83, 0.15)' : 'rgba(244, 67, 54, 0.15)',
        color: (theme) => (value >= 0 ? theme.palette.green[700] : theme.palette.red[700]),
        fontSize: '12px',
        padding: '1px 8px',
        margin: '0 10px',
        borderRadius: '8px',
        minWidth: '60px',
      }}
      {...boxProps}
    >
      {value >= 0 ? `+${formattedValue}` : formattedValue}
    </Box>
  );
};
