import { BunkScoreValue, FeedingMethod, PlanCallMethod, PlanCallUnit } from '@/web-types';
import { useCallback, useMemo } from 'react';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { sumBy } from 'lodash';
import { normalizeDistribution } from '../callPlan/functions';
import {
  addOneTimeDrop,
  addRecurringDrop,
  calcCalls,
  CallAmount,
  CallFeedDropIdAndActual,
  CallFeedDropsMap,
  Day,
  deleteOneTimeDrop,
  deleteRecurringDrop,
  Empty,
  PenCallPlan,
  PenCallPlanDay,
  PenState,
  resolveDay,
  setAfPerHead,
  setAfTotal,
  setDistributionPercents,
  setDmiPerHead,
  setDropAmount,
  setDropRation,
  setOneTimeDropAmount,
  setOneTimeDropRation,
  setPenRation,
} from '../callPlan';
import { assertImpossible } from '@/common/utils/assertImpossible';
import { PlanAndObservations } from './useCallFeedPlanData';
import { Input } from '@/components/types';

/*
 * This is the adapter layer between the plan structure and the call feed UI
 * This defines the behavior of how to display the current plan, and how to update the plan with changes
 */
// TODO (Aurel) comments
type CallFeedStateArgs = {
  yesterdayNumHead: number;
  currentHead: number;
  rationIdToDmRatio: RationToDryMatterRatio;
  planYesterday: PenCallPlan;
  data: PlanAndObservations;
  setData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void;
  dropsMapYesterday: readonly CallFeedDropIdAndActual[];
  dropsMap: CallFeedDropsMap;
  feedingMethod: FeedingMethod;
  /** Weight per head of the pen yesterday */
  yesterdayWeightPerHeadLbs: number | null;
  currentWeightPerHeadLbs: number | null;
  defaultRationId: number;
};

export function useCallFeedUiState({
  yesterdayNumHead,
  currentHead,
  rationIdToDmRatio,
  planYesterday,
  dropsMapYesterday,
  data,
  setData,
  dropsMap,
  feedingMethod,
  yesterdayWeightPerHeadLbs,
  currentWeightPerHeadLbs,
  defaultRationId,
}: CallFeedStateArgs): CallFeedUiState {
  // construct an object from the parts of the pen state
  const penState: PenState = useMemo(
    () => ({ currentHead, rationIdToDmRatio, dropsMap, defaultRationId }),
    [currentHead, rationIdToDmRatio, dropsMap, defaultRationId]
  );
  const penStateYesterday: PenState = useMemo(
    () => ({
      currentHead: yesterdayNumHead,
      rationIdToDmRatio,
      dropsMap: { today: dropsMapYesterday, tomorrow: [] },
      defaultRationId,
    }),
    [currentHead, rationIdToDmRatio, dropsMap, defaultRationId]
  );
  // get stable modifier functions
  const modifiers = useCallFeedModifiers(setData, penState);
  // construct the UI state
  const uiState: CallFeedUiState = useMemo(() => {
    const { plan, observationsToday } = data;
    return {
      observations: {
        bunkScore: {
          value: observationsToday.bunkScore,
          onChange: modifiers.forObservations.onChangeBunkScore,
        },
      },
      today: todayStateFromPlanAndActual(
        planYesterday,
        plan,
        penStateYesterday,
        penState,
        modifiers.forPlan.today,
        feedingMethod,
        yesterdayWeightPerHeadLbs,
        currentWeightPerHeadLbs
      ),
      tomorrow: tomorrowStateFromPlan(plan, penState, modifiers.forPlan.tomorrow, currentWeightPerHeadLbs),
      distributionPercents:
        plan.method === PlanCallMethod.ByDrop
          ? undefined
          : {
              value: plan.distribution.map((v) => v * 100),
              onChange: modifiers.forPlan.onChangeDistributionPercents,
            },
    };
  }, [data, modifiers, penStateYesterday, penState, feedingMethod, currentWeightPerHeadLbs]);
  return uiState;
}

type Modifiers = ReturnType<typeof useCallFeedModifiers>;

// This function provides stable modifiers for call plans
// The modifier functions themselves only change state of the pen changes (head, actuals, rations)
function useCallFeedModifiers(
  setData: (fn: (prev: PlanAndObservations) => PlanAndObservations) => void,
  penState: PenState
) {
  const setPlan = useCallback(
    (fn: (prev: PenCallPlan) => PenCallPlan) => setData((prevData) => ({ ...prevData, plan: fn(prevData.plan) })),
    [setData]
  );
  const setPlanWithState = useCallback(
    (fn: (prev: PenCallPlan, state: PenState) => PenCallPlan) => setPlan((prev) => fn(prev, penState)),
    [setPlan, penState]
  );
  const forObservations = useMemo(
    () => ({
      onChangeBunkScore: (bunkScore: BunkScoreValue | undefined) =>
        setData((prevData) => ({ ...prevData, observationsToday: { ...prevData.observationsToday, bunkScore } })),
      onChangeAgressionScore: (agressionScore: number | undefined) =>
        setData((prevData) => ({ ...prevData, observationsToday: { ...prevData.observationsToday, agressionScore } })),
      onChangeNotes: (notes: string) =>
        setData((prevData) => ({
          ...prevData,
          observationsToday: { ...prevData.observationsToday, notes: notes.trim() },
        })),
    }),
    [setData]
  );
  const forPlan = useMemo(() => {
    return {
      // set the distributions as a unit
      onChangeDistributionPercents: (value: readonly number[]) =>
        setPlanWithState((plan, state) => setDistributionPercents(plan, value, state.defaultRationId)),
      today: {
        onChangePenRation: (value: number) => setPlan((plan) => setPenRation(plan, 'today', value)),
        onChangeDmiPerHead: (value: number) =>
          setPlanWithState((plan, state) => setDmiPerHead(plan, state, 'today', value)),
        onChangeAfPerHead: (value: number) =>
          setPlanWithState((plan, state) => setAfPerHead(plan, state, 'today', value)),
        onChangeAfTotal: (value: number) => setPlanWithState((plan, state) => setAfTotal(plan, state, 'today', value)),
        onChangeDropRation: (value: number, params: unknown) =>
          setPlan((plan) => setDropRation(plan, 'today', Number(params), value)),
        onChangeDropAmount: (value: CallAmount, params: unknown) =>
          setPlanWithState((plan, state) => setDropAmount(plan, state, 'today', Number(params), value)),
        onChangeOneTimeDropRation: (value: number, params: unknown) =>
          setPlan((plan) => setOneTimeDropRation(plan, Number(params), value)),
        onChangeOneTimeDropAfLbs: (value: number, params: unknown) =>
          setPlan((plan) => setOneTimeDropAmount(plan, Number(params), value)),
        onDeleteOneTimeDrop: (params: unknown) => setPlan((plan) => deleteOneTimeDrop(plan, Number(params))),
        onAddOneTimeDrop: (rationId: number) => setPlan((plan) => addOneTimeDrop(plan, rationId)),
        onDeleteRecurringDrop: (params: unknown) =>
          setPlan((plan) => deleteRecurringDrop(plan, 'today', Number(params))),
        onAddRecurringDrop: (rationId: number) => setPlan((plan) => addRecurringDrop(plan, 'today', rationId)),
      },
      tomorrow: {
        onChangePenRation: (value: number) => setPlan((plan) => setPenRation(plan, 'tomorrow', value)),
        onChangeDmiPerHead: (value: number) =>
          setPlanWithState((plan, state) => setDmiPerHead(plan, state, 'tomorrow', value)),
        onChangeAfPerHead: (value: number) =>
          setPlanWithState((plan, state) => setAfPerHead(plan, state, 'tomorrow', value)),
        onChangeAfTotal: (value: number) =>
          setPlanWithState((plan, state) => setAfTotal(plan, state, 'tomorrow', value)),
        onChangeDropRation: (value: number, params: unknown) =>
          setPlan((plan) => setDropRation(plan, 'tomorrow', Number(params), value)),
        onChangeDropAmount: (value: CallAmount, params: unknown) =>
          setPlanWithState((plan, state) => setDropAmount(plan, state, 'tomorrow', Number(params), value)),
        onDeleteRecurringDrop: (params: unknown) =>
          setPlan((plan) => deleteRecurringDrop(plan, 'tomorrow', Number(params))),
        onAddRecurringDrop: (rationId: number) => setPlan((plan) => addRecurringDrop(plan, 'tomorrow', rationId)),
      },
    };
  }, [setPlanWithState, setPlan]);
  return { forPlan, forObservations };
}

function planForDay(wholePlan: PenCallPlan, day: Day): { plan: PenCallPlanDay; distributionPercents: number[] } {
  const plan = resolveDay(wholePlan, day);
  // distributions work differently for per drop and per head
  // for per head, the actual distribution is hidden and we show the plan distribution
  const distributionPercents =
    wholePlan.method === PlanCallMethod.ByDrop
      ? plan.method === PlanCallMethod.ByDrop
        ? normalizeDistribution(plan.dropCallAmounts).map((d) => d * 100)
        : assertImpossible('plan.method !== wholePlan.method')
      : wholePlan.distribution.map<number>((d, i) => d * 100);
  return { plan, distributionPercents };
}

function penAmounts(
  plan: PenCallPlanDay,
  calls: ReturnType<typeof calcCalls>,
  rationIdToDmRatio: RationToDryMatterRatio
): { dmiLbsPerHead: number; afLbsPerHead: number; afLbsTotal: number } {
  const dmiLbsPerHead =
    plan.method === PlanCallMethod.ByDistribution && plan.callUnit === PlanCallUnit.DmiLbsPerHead
      ? plan.callAmount
      : sumBy(calls, (c) => (c.amount.afLbs / c.head) * rationIdToDmRatio(c.rationId));
  const afLbsPerHead =
    plan.method === PlanCallMethod.ByDistribution && plan.callUnit === PlanCallUnit.AsFedLbsPerHead
      ? plan.callAmount
      : sumBy(calls, (c) => c.amount.afLbs / c.head);
  const afLbsTotal = sumBy(calls, (c) => c.amount.afLbs);
  return { dmiLbsPerHead, afLbsPerHead, afLbsTotal };
}

/**
 * This function calculates the UI state for today
 */
function todayStateFromPlanAndActual(
  wholePlanYesterday: PenCallPlan,
  wholePlan: PenCallPlan,
  penStateYesterday: PenState,
  penState: PenState,
  modifiers: Modifiers['forPlan']['today'],
  feedingMethod: FeedingMethod,
  yesterdayWeightPerHeadLbs: number | null,
  currentWeightPerHeadLbs: number | null
): CallFeedDayUiState {
  const { plan: planYesterday } = planForDay(wholePlanYesterday, 'today');
  const { plan, distributionPercents } = planForDay(wholePlan, 'today');

  // this is the same regardless of call method
  const rationId = {
    value: wholePenRation(plan.rationIds),
    onChange: modifiers.onChangePenRation,
  };
  const callsYesterday = calcCalls(wholePlanYesterday, penStateYesterday, 'today');
  const calls = calcCalls(wholePlan, penState, 'today');

  const {
    dmiLbsPerHead: dmiLbsPerHeadPreviousDay,
    afLbsPerHead: afLbsPerHeadPreviousDay,
    afLbsTotal: afLbsPreviousDay,
  } = penAmounts(planYesterday, callsYesterday, penStateYesterday.rationIdToDmRatio);
  const { dmiLbsPerHead, afLbsPerHead, afLbsTotal } = penAmounts(plan, calls, penState.rationIdToDmRatio);

  const { dropsMap: drops } = penState;

  // for AfPerDrop, once all drops are fed, no editing of pen level values is allowed (until we define how that should work)
  const penLevelLocked =
    plan.method === PlanCallMethod.ByDrop &&
    calls.every((call) => drops.today.some((a) => a.dropId === call.dropId && a.actual));

  const dmAsPercentOfBW = currentWeightPerHeadLbs ? dmiLbsPerHead / currentWeightPerHeadLbs : null;
  const dmAsPercentOfBWPreviousDay = yesterdayWeightPerHeadLbs
    ? dmiLbsPerHeadPreviousDay / yesterdayWeightPerHeadLbs
    : null;

  return {
    rationId,
    dmiLbsPerHeadPreviousDay,
    dmiLbsPerHead: {
      value: dmiLbsPerHead,
      onChange: modifiers.onChangeDmiPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.AsFedLbsPerHead,
    },
    dmiLbsPerHeadDayDifference: dmiLbsPerHead - dmiLbsPerHeadPreviousDay,
    afLbsPerHeadPreviousDay,
    afLbsPerHead: {
      value: afLbsPerHead,
      onChange: modifiers.onChangeAfPerHead,
      disabled: penLevelLocked || plan.callUnit === PlanCallUnit.DmiLbsPerHead,
    },
    afLbsPerHeadDayDifference: afLbsPerHead - afLbsPerHeadPreviousDay,
    afLbsPreviousDay,
    afLbsDayDifference: afLbsTotal - afLbsPreviousDay,
    afLbs: {
      value: afLbsTotal,
      onChange: modifiers.onChangeAfTotal,
      disabled: penLevelLocked || plan.callUnit !== PlanCallUnit.AsFedLbs,
    },
    dmAsPercentOfBWPreviousDay,
    dmAsPercentOfBW,
    dmAsPercentOfBWDayDifference: (dmAsPercentOfBW ?? 0) - (dmAsPercentOfBWPreviousDay ?? 0),
    drops: calls.map<CallFeedDropUiState>(({ dropId, rationId, amount }, i) => ({
      dropId,
      distributionPercent: distributionPercents[i] ?? 'one-time',
      rationId: {
        params: i,
        value: rationId,
        onChange: modifiers.onChangeDropRation,
        disabled:
          // disabled if this is an auto-added drop (TODO: consider supporting this)
          i >= distributionPercents.length ||
          // disabled if this drop is loaded or fed
          drops.today.some((a) => a.dropId === dropId && a.actual),
      },
      lbsCalled: {
        params: i,
        value: amount,
        onChange: modifiers.onChangeDropAmount,
        disabled:
          // disabled if this is an auto-added drop (TODO: consider supporting this)
          i >= distributionPercents.length ||
          // disabled if this is an actual and we're not a batch feeder
          (drops.today.some((a) => a.dropId === dropId && a.actual) &&
            !(feedingMethod === FeedingMethod.Batch && plan.method === PlanCallMethod.ByDrop)),
      },
      lbsFed: drops.today.find((a) => a.dropId === dropId)?.actual?.afLbsFed ?? 0,
      onDelete:
        plan.method === PlanCallMethod.ByDrop && !drops.today.some((a) => a.dropId === dropId && a.actual)
          ? modifiers.onDeleteRecurringDrop
          : undefined,
    })),
    oneTimeDrops: plan.oneTimeDrops.map((r, i) => ({
      id: r.id,
      rationId: {
        params: i,
        value: r.rationId,
        onChange: modifiers.onChangeOneTimeDropRation,
        disabled: r.afLbs > 0,
      },
      lbsCalled: {
        params: i,
        value: r.afLbs,
        onChange: modifiers.onChangeOneTimeDropAfLbs,
        disabled: r.afLbs > 0,
      },
      lbsFed: drops.today.find((a) => a.dropId === r.id)?.actual?.afLbsFed ?? 0,
      onDelete: r.afLbs > 0 ? undefined : modifiers.onDeleteOneTimeDrop,
    })),
    onAddOneTimeDrop: modifiers.onAddOneTimeDrop,
    onAddRecurringDrop: plan.method === PlanCallMethod.ByDrop ? modifiers.onAddRecurringDrop : undefined,
  };
}

/**
 * This function calculates the UI state for today or tomorrow
 */
function tomorrowStateFromPlan(
  wholePlan: PenCallPlan,
  penState: PenState,
  modifiers: Modifiers['forPlan']['tomorrow'],
  currentWeightPerHeadLbs: number | null
): CallFeedDayUiState {
  const { plan, distributionPercents } = planForDay(wholePlan, 'tomorrow');

  // this is the same regardless of call method
  const rationId = {
    value: wholePenRation(plan.rationIds),
    onChange: modifiers.onChangePenRation,
  };
  const calls = calcCalls(wholePlan, penState, 'tomorrow');
  const { dmiLbsPerHead, afLbsPerHead, afLbsTotal } = penAmounts(plan, calls, penState.rationIdToDmRatio);

  const dmAsPercentOfBW = currentWeightPerHeadLbs ? dmiLbsPerHead / currentWeightPerHeadLbs : null;

  const dmiLbsPerHeadPreviousDay = dmiLbsPerHead * 0.9; // TODO: this should be the real value
  const afLbsPreviousDay = afLbsTotal * 0.9; // TODO: this should be the real value
  const afLbsPerHeadPreviousDay = afLbsPerHead * 0.9; // TODO: this should be the real value
  const dmAsPercentOfBWPreviousDay = 0.01; // 1% // TODO: this should be the real value

  return {
    rationId,
    dmiLbsPerHeadPreviousDay,
    dmiLbsPerHead: {
      value: dmiLbsPerHead,
      onChange: modifiers.onChangeDmiPerHead,
      disabled: plan.callUnit === PlanCallUnit.AsFedLbsPerHead,
    },
    dmiLbsPerHeadDayDifference: dmiLbsPerHead - dmiLbsPerHeadPreviousDay,
    afLbsPerHeadPreviousDay,
    afLbsPerHead: {
      value: afLbsPerHead,
      onChange: modifiers.onChangeAfPerHead,
      disabled: plan.callUnit === PlanCallUnit.DmiLbsPerHead,
    },
    afLbsPerHeadDayDifference: afLbsPerHead - afLbsPerHeadPreviousDay,
    afLbsPreviousDay,
    afLbsDayDifference: afLbsTotal - afLbsPreviousDay,
    afLbs: {
      value: afLbsTotal,
      onChange: modifiers.onChangeAfTotal,
      disabled: plan.callUnit !== PlanCallUnit.AsFedLbs,
    },
    dmAsPercentOfBWPreviousDay,
    dmAsPercentOfBW,
    dmAsPercentOfBWDayDifference: (dmAsPercentOfBW ?? 0) - (dmAsPercentOfBWPreviousDay ?? 0),
    drops: calls.map<CallFeedDropUiState>(({ dropId, rationId, amount }, i) => ({
      dropId,
      distributionPercent: distributionPercents[i] ?? 'one-time',
      rationId: {
        params: i,
        value: rationId,
        onChange: modifiers.onChangeDropRation,
      },
      lbsCalled: {
        params: i,
        value: amount,
        onChange: modifiers.onChangeDropAmount,
      },
      lbsFed: 0,
      onDelete: plan.method === PlanCallMethod.ByDrop ? modifiers.onDeleteRecurringDrop : undefined,
    })),
    oneTimeDrops: Empty,
    onAddOneTimeDrop: undefined,
    onAddRecurringDrop: plan.method === PlanCallMethod.ByDrop ? modifiers.onAddRecurringDrop : undefined,
  };
}

/** Calculates the value of the ration for the whole pen from the selected ration of the drops */
function wholePenRation(rationIds: readonly number[]): number | 'multiple' | undefined {
  if (rationIds.length === 0) return undefined;
  const unique = new Set(rationIds);
  if (unique.size === 1) return rationIds[0];
  return 'multiple';
}

// The types that represent the state of the UI for Call Feed

export type CallFeedUiState = {
  /** The fields for today  */
  today: CallFeedDayUiState;
  /** The fields for tomorrow  */
  tomorrow: CallFeedDayUiState;
  /** undefined for per-drop plans */
  distributionPercents: undefined | Input<readonly number[]>;
  observations: {
    bunkScore: Input<BunkScoreValue | undefined>;
  };
};

// TODO: comments
export type CallFeedDayUiState = {
  rationId: Input<number | undefined | 'multiple', number>;
  dmiLbsPerHeadPreviousDay: number;
  dmiLbsPerHead: Input<number>;
  dmiLbsPerHeadDayDifference: number;
  afLbsPerHeadPreviousDay: number;
  afLbsPerHead: Input<number>;
  afLbsPerHeadDayDifference: number;
  afLbsPreviousDay: number;
  afLbsDayDifference: number;
  afLbs: Input<number>;
  /** Dry Matter as percent of Body Weight */
  dmAsPercentOfBW: number | null;
  dmAsPercentOfBWPreviousDay: number | null;
  dmAsPercentOfBWDayDifference: number;
  drops: readonly CallFeedDropUiState[];
  oneTimeDrops: readonly CallFeedOneTimeDropUiState[];
  onAddOneTimeDrop: ((rationId: number) => void) | undefined;
  onAddRecurringDrop: ((rationId: number) => void) | undefined;
};

export type CallFeedDropUiState = {
  dropId: number | undefined;
  distributionPercent: 'one-time' | number;
  rationId: Input<number>;
  lbsCalled: Input<CallAmount>;
  lbsFed: number;
  onDelete: ((params: number) => void) | undefined;
};

export type CallFeedOneTimeDropUiState = {
  id: number | undefined;
  rationId: Input<number>;
  lbsCalled: Input<number>;
  lbsFed: number;
  onDelete: ((params: number) => void) | undefined;
};
