import { PlanCallUnit } from '@/web-types';
import {
  Day,
  Empty,
  PenCallAsFedByDropPlan,
  PenCallAsFedByDropPlanDay,
  PenCallPlanFunctions,
  CallFeedDropsMap,
  rationOrLast,
} from '.';
import { redistributeAroundForced } from './functions';
import { sum } from 'lodash';
import { calcPerDropCalls } from './byDropFunctions';
import assertNever from 'assert-never';

type T = PenCallPlanFunctions<PenCallAsFedByDropPlan>;

export const resolveDay = (plan: PenCallAsFedByDropPlan, day: Day): PenCallAsFedByDropPlanDay =>
  day === 'today'
    ? {
        method: plan.method,
        rationIds: plan.rationIds,
        dropCallAmounts: plan.dropCallAmounts,
        callUnit: plan.callUnit,
        oneTimeDrops: plan.oneTimeDropsToday ?? Empty,
        mapIndexes: plan.mapIndexes,
      }
    : {
        method: plan.method,
        rationIds: plan.rationIdsAfterToday ?? plan.rationIds,
        dropCallAmounts: plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts,
        callUnit: plan.callUnit,
        oneTimeDrops: Empty,
        mapIndexes: plan.mapIndexesAfterToday ?? plan.mapIndexes,
      };

export const calcCalls: T['calcCalls'] = (plan, state, day) => {
  const planDay = resolveDay(plan, day);
  return calcPerDropCalls(planDay, state.dropsMap[day], state.currentHead);
};

// editing percents of a per drop plan isn't defined
export const setDistributionPercents: T['setDistributionPercents'] = () => {
  throw new Error('Not implemented');
};

export const setDmiPerHead: T['setDmiPerHead'] = (plan, state, day, dmiLbsPerHead) => {
  const { currentHead, rationIdToDmRatio } = state;
  const rationIds = day === 'today' ? plan.rationIds : (plan.rationIdsAfterToday ?? plan.rationIds);
  const before = day === 'today' ? plan.dropCallAmounts : (plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts);
  // get per drop values
  const dmiPerHeads = before.map((afLbs, i) => {
    const a = state.dropsMap[day][i]?.actual;
    return a
      ? (a.afLbsCalled / a.head) * rationIdToDmRatio(a.rationId)
      : (afLbs / currentHead) * rationIdToDmRatio(rationOrLast(rationIds, i));
  });
  // adjust all of the values to get to the new total value
  const adjustment = dmiLbsPerHead / sum(dmiPerHeads);
  const adjusted = dmiPerHeads.map((d, i) =>
    Number.isFinite(adjustment) ? d * adjustment : dmiLbsPerHead / dmiPerHeads.length
  );
  // redistribute around any actuals
  const force = dmiPerHeads.map((d, i) => (state.dropsMap[day][i]?.actual ? d : undefined));
  const redistributed = redistributeAroundForced(adjusted, force).filter((f) => f >= 0);
  // convert back to af per drop
  const after = redistributed.map((dmiPerHead, i) => {
    const a = state.dropsMap[day][i]?.actual;
    return a ? a.afLbsCalled : (dmiPerHead * currentHead) / rationIdToDmRatio(rationOrLast(rationIds, i));
  });
  return { ...plan, [day === 'today' ? 'dropCallAmounts' : 'dropCallAmountsAfterToday']: after };
};

export const setAfPerHead: T['setAfPerHead'] = (plan, state, day, afLbsPerHead) => {
  const { currentHead } = state;
  const before = day === 'today' ? plan.dropCallAmounts : (plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts);
  // get per drop values
  const afPerHeads = before.map((afLbs, i) => {
    const a = state.dropsMap[day][i]?.actual;
    return a ? a.afLbsCalled / a.head : afLbs / currentHead;
  });
  // adjust all of the values to get to the new total value
  const adjustment = afLbsPerHead / sum(afPerHeads);
  const adjusted = afPerHeads.map((d, i) =>
    Number.isFinite(adjustment) ? d * adjustment : afLbsPerHead / afPerHeads.length
  );
  // redistribute around any actuals
  const force = afPerHeads.map((d, i) => (state.dropsMap[day][i]?.actual ? d : undefined));
  const redistributed = redistributeAroundForced(adjusted, force).filter((f) => f >= 0);
  // convert back to af per drop
  const after = redistributed.map((afPerHead, i) => {
    const a = state.dropsMap[day][i]?.actual;
    return a ? a.afLbsCalled : afPerHead * currentHead;
  });
  return { ...plan, [day === 'today' ? 'dropCallAmounts' : 'dropCallAmountsAfterToday']: after };
};

export const setAfTotal: T['setAfTotal'] = (plan, state, day, afLbsTotal) => {
  const before = day === 'today' ? plan.dropCallAmounts : (plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts);
  // get per drop values
  const afLbsDrops = before.map((afLbs, i) => {
    const a = state.dropsMap[day][i]?.actual;
    return a ? a.afLbsCalled : afLbs;
  });
  // adjust all of the values to get to the new total value
  const adjustment = afLbsTotal / sum(afLbsDrops);
  const adjusted = afLbsDrops.map((d, i) =>
    Number.isFinite(adjustment) ? d * adjustment : afLbsTotal / afLbsDrops.length
  );
  // redistribute around any actuals
  const force = afLbsDrops.map((d, i) => (state.dropsMap[day][i]?.actual ? d : undefined));
  const redistributed = redistributeAroundForced(adjusted, force);
  // convert back to af per drop
  const afLbs = redistributed.filter((f) => f >= 0);
  return { ...plan, [day === 'today' ? 'dropCallAmounts' : 'dropCallAmountsAfterToday']: afLbs };
};

export const setDropAmount: T['setDropAmount'] = (plan, _state, day, dropIndex, dropAmount) => {
  const before = day === 'today' ? plan.dropCallAmounts : (plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts);
  const after = before.map((afLbs, i) => (i === dropIndex ? dropAmount.afLbs : afLbs));
  return { ...plan, [day === 'today' ? 'dropCallAmounts' : 'dropCallAmountsAfterToday']: after };
};

export const deleteRecurringDrop: T['deleteRecurringDrop'] = (plan, day, dropIndex) => {
  if (day === 'today') {
    return {
      ...plan,
      rationIds: plan.rationIds.filter((_, i) => i !== dropIndex),
      dropCallAmounts: plan.dropCallAmounts.filter((_, i) => i !== dropIndex),
      mapIndexes: plan.mapIndexes.filter((_, i) => i !== dropIndex),
    };
  } else if (day === 'tomorrow') {
    return {
      ...plan,
      rationIdsAfterToday: (plan.rationIdsAfterToday ?? plan.rationIds).filter((_, i) => i !== dropIndex),
      dropCallAmountsAfterToday: (plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts).filter(
        (_, i) => i !== dropIndex
      ),
      mapIndexesAfterToday: (plan.mapIndexesAfterToday ?? plan.mapIndexes).filter((_, i) => i !== dropIndex),
    };
  }
  assertNever(day);
};

export const addRecurringDrop: T['addRecurringDrop'] = (plan, day, rationId) => {
  if (day === 'today') {
    const rationIds = plan.rationIds;
    const dropCallAmounts = plan.dropCallAmounts;
    return {
      ...plan,
      rationIds: [...rationIds, rationId != null ? rationId : rationIds.at(-1)!],
      dropCallAmounts: [...dropCallAmounts, 0],
    };
  } else if (day === 'tomorrow') {
    const rationIds = plan.rationIdsAfterToday ?? plan.rationIds;
    const dropCallAmounts = plan.dropCallAmountsAfterToday ?? plan.dropCallAmounts;
    return {
      ...plan,
      rationIdsAfterToday: [...rationIds, rationId != null ? rationId : rationIds.at(-1)!],
      dropCallAmountsAfterToday: [...dropCallAmounts, 0],
    };
  }
  assertNever(day);
};
