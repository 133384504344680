import { DialogActions, DialogContainer, DialogTitle } from '@/components/Dialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { BunkScoreValue } from '@/web-types';
import { bunkScoreOptions } from '@/components/BunkScoringTab';
import { useCallback, useState } from 'react';

interface ObservationsModalProps {
  value: BunkScoreValue | undefined;
  onChange: (value: BunkScoreValue | undefined) => void;
}

export const showObservationsModal = (props: ObservationsModalProps) => ModalManager.show(ObservationsModal, props);

const ObservationsModal = ModalManager.create<ObservationsModalProps>(({ value, onChange }) => {
  const isPhoneSize = useIsPhoneSize();
  const [bunkScore, setBunkScore] = useState<BunkScoreValue | undefined>(value);

  const modal = useModal();

  const onSubmit = useCallback(async () => {
    onChange(bunkScore);
    modal.remove();
  }, [bunkScore, modal, onChange]);

  return (
    <Dialog onClose={modal.remove} open={modal.visible} maxWidth="sm" fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogTitle title="Bunk Score" onCloseClick={() => modal.remove()} />

        <Box sx={{ py: 3 }}>
          <BunkScoreSelector value={bunkScore} onChange={setBunkScore} />
        </Box>

        <DialogActions>
          <Button variant="text" type="reset" onClick={modal.remove}>
            Cancel
          </Button>
          <LoadingButton type="submit" onClick={() => onSubmit()}>
            Save
          </LoadingButton>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
});

const BunkScoreSelector = ({
  value,
  onChange,
}: {
  value: BunkScoreValue | undefined;
  onChange: (value: BunkScoreValue | undefined) => void;
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Button
          sx={{
            py: 0.2,
            borderRadius: 3,
          }}
          variant={value === undefined ? 'contained' : 'outlined'}
          fullWidth
          onClick={() => onChange(undefined)}
        >
          {'--'}
        </Button>
      </Grid>

      {bunkScoreOptions.map((option) => (
        <Grid item key={option.value} xs={4}>
          <Button
            sx={{
              py: 0.2,
              borderRadius: 3,
            }}
            variant={option.value === value ? 'contained' : 'outlined'}
            fullWidth
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
