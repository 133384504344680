import { RationToDryMatterRatio } from '@/common/models/ration';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { IncrementalNumberInput } from './controls/IncrementalNumberInput';
import { RationInput } from './controls/RationInput';
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  ModalProps,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { FeedingMethod, PenLot } from '@/web-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { PercentsInput } from './controls/PercentsInput';
import { CallAmountInput } from './controls/CallAmountInput';
import { DialogActions, DialogContainer, DialogContent, DialogTitle } from '@/components/Dialog';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import LaunchIcon from '@mui/icons-material/Launch';
import { PenChangeStack } from '@/components/CallFeed/PenChangeStack';
import { TodayTomorrowStack } from '@/components/CallFeed/TodayTomorrowStack';
import SettingsIcon from '@mui/icons-material/Settings';
import { showCallFeedSettingsModal } from '@/components/CallFeedTab/CallFeedSettingsModal';
import { PenLotToolbar } from '@/components/CallFeed/PenLotToolbar';
import { Subset } from '@/common/types/subset';
import { CallFeedDropIdAndActual, CallFeedDropsMap, Day, PenCallPlan } from '@/components/CallFeed/callPlan';
import { CallFeedDropUiState, useCallFeedUiState } from '@/components/CallFeed/hooks/useCallFeedUiState';
import { RationOption } from '@/components/CallFeed/hooks/useRations';
import { useWorking } from '@/components/CallFeed/hooks/useWorking';
import { PlanAndObservations } from '@/components/CallFeed/hooks/useCallFeedPlanData';
import { PenHistoryBox } from '@/components/CallFeed/PenHistoryBox';
import { Error, KeyboardReturn } from '@mui/icons-material';
import { ObservationsBox } from '@/components/CallFeed/ObservationsBox';
import { FeedPlanBox } from '@/components/CallFeed/FeedPlanBox';
import { DuplicateDropsEmptyView } from '@/components/CallFeedTab/DuplicateDropsEmptyView';
import { formatDateForDB } from '@/components/helpers/format';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { useIsPhoneSize } from '../hooks/useResponsive';
import { SaveResult } from './hooks/useCallFeedPlanDropsAndActuals';

const today = dayjs().format('YYYY-MM-DD');
const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const StyledTableCell = styled(TableCell)(() => ({
  padding: '5px 10px',
  textAlign: 'center',
}));

export const CallFeedPlan = ({
  penId,
  yesterdayNumHead,
  currentHead,
  rations,
  rationIdToDmRatio,
  dropsMapYesterday,
  dropsMap: actual,
  planYesterday,
  savedData,
  saveData,
  feedingMethod,
  increments,
  onExit,
  penLots,
  yesterdayWeightPerHeadLbs,
  currentWeightPerHeadLbs,
  onChangePen,
  haveTomorrow,
  day,
  setDay,
  refetch,
}: {
  penId: number;
  yesterdayNumHead: number;
  currentHead: number;
  rations: readonly RationOption[];
  rationIdToDmRatio: RationToDryMatterRatio;
  dropsMapYesterday: readonly CallFeedDropIdAndActual[];
  dropsMap: CallFeedDropsMap;
  planYesterday: PenCallPlan;
  savedData: PlanAndObservations;
  saveData: (plan: PlanAndObservations) => Promise<SaveResult>;
  feedingMethod: FeedingMethod;
  increments: { dmiPerHead: number; afPerHead: number; af: number };
  onExit?: (isTodaySelected: boolean) => void;
  penLots: Subset<PenLot>[];
  /** Weight per head of the pen yesterday */
  yesterdayWeightPerHeadLbs: number | null;
  currentWeightPerHeadLbs: number | null;
  onChangePen: (penId: number) => void;
  haveTomorrow: boolean;
  day: Day;
  setDay: (day: Day) => void;
  refetch: () => void;
}) => {
  const _today = dayjs.tz();
  const _tomorrow = _today.add(1, 'day');

  const [selectedPenLotId, setSelectedPenLotId] = useState<number | undefined>();
  const [showEditDropDistributionModal, setShowEditDropDistributionModal] = useState(false);
  const [workingData, setWorkingData, flags, saveWorking] = useWorking(savedData, saveData);
  const state = useCallFeedUiState({
    yesterdayNumHead,
    currentHead,
    rationIdToDmRatio,
    planYesterday,
    dropsMapYesterday,
    data: workingData,
    setData: setWorkingData,
    dropsMap: actual,
    feedingMethod,
    yesterdayWeightPerHeadLbs,
    currentWeightPerHeadLbs,
    defaultRationId: rations[0].id,
  });

  const save = useCallback(async () => {
    const result = await saveWorking();
    if (result !== 'success') {
      const response = await showSaveFailedModal(result.error.graphQLErrors[0].message);
      if (response === 'undo-and-retry') {
        setWorkingData((_) => savedData);
      }
    }
    return result;
  }, [saveWorking, savedData, setWorkingData]);

  const currentDay = state[day];

  const dropCount = Math.max(state.today.drops.length, state.tomorrow.drops.length);

  const saveAndExit = useCallback(async () => {
    if (flags.dirty) {
      const result = await save();
      if (result !== 'success') {
        return;
      }
    }
    onExit?.(day === 'today');
  }, [flags.dirty, day, onExit, save]);

  const saveAndChangePen = useCallback(
    async (penId: number) => {
      if (flags.dirty) {
        const result = await save();
        if (result !== 'success') {
          return;
        }
      }
      onChangePen(penId);
    },
    [flags.dirty, onChangePen, save]
  );

  const selectedPenLot = useMemo(() => {
    if (!selectedPenLotId) return undefined;
    return penLots.find((p) => p.id === selectedPenLotId);
  }, [selectedPenLotId, penLots]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', py: 1, px: 2 }}>
        <PenChangeStack penId={penId} onChangePen={saveAndChangePen} />

        <Box display="flex" alignItems="center" gap={1}>
          <TodayTomorrowStack
            value={day}
            onChange={(value) => {
              if (value === 'tomorrow' && flags.dirty && !haveTomorrow) {
                confirmSave(save).then((confirmed) => {
                  if (confirmed) setDay(value);
                });
                return;
              }
              setDay(value);
            }}
          />
          <IconButton onClick={() => showCallFeedSettingsModal({ defaultTabIndex: 1 })}>
            <SettingsIcon />
          </IconButton>
          <Button
            sx={{ minWidth: 0 }}
            variant="text"
            color="inherit"
            startIcon={<KeyboardReturn />}
            disabled={flags.saving}
            onClick={saveAndExit}
            data-testid="save-and-exit-button"
          >
            {flags.saving ? 'Saving ...' : flags.dirty ? 'Save And Exit' : 'Exit'}
          </Button>
        </Box>
      </Box>

      <PenLotToolbar penLots={penLots} value={selectedPenLotId} onChange={setSelectedPenLotId} />

      {!haveTomorrow && day === 'tomorrow' ? (
        <DuplicateDropsEmptyView
          isFullScreen={false}
          fromDate={formatDateForDB(_today)}
          toDate={formatDateForDB(_tomorrow)}
          onSuccess={() => refetch()}
        />
      ) : (
        <Grid
          container
          px={2}
          pb={2}
          sx={{ background: (theme) => (day === 'today' ? theme.palette.grey[100] : theme.palette.blue[50]) }}
        >
          <Grid item xs={12} sm={6} md={5} sx={{ mt: 2, p: 1 }}>
            {day == 'today' && <ObservationsBox bunkScore={state.observations.bunkScore} />}
            <FeedPlanBox day={day} currentDay={currentDay} increments={increments} />
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Box maxHeight={260} overflow="auto">
              <TableContainer>
                <Table>
                  <TableBody>
                    {day === 'today' && (
                      <TableRow>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>Drop</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>%</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            width: { xs: 1, md: 'auto' },
                            textAlign: 'center',
                          }}
                        >
                          Ration
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', minWidth: 200 }}>Called</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>Fed</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto', textAlign: 'right' }}>
                          {dayName[dayjs(today).add(1, 'day').day()]}
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {day === 'tomorrow' && (
                      <TableRow>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>Drop</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>%</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            width: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          {dayName[dayjs(today).day()]}
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>Fed</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', width: 'auto' }}>Ration</StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            minWidth: 200,
                            width: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          {dayName[dayjs(today).add(1, 'day').day()]}
                        </StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    )}

                    {day === 'today' && (
                      <TableRow sx={{ background: (theme) => theme.palette.grey[300] }}>
                        <StyledTableCell>Total</StyledTableCell>
                        <StyledTableCell>100%</StyledTableCell>
                        <StyledTableCell>
                          <RationInput rations={rations} {...currentDay.rationId} data-testid="drops-total-ration" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IncrementalNumberInput
                            increment={increments.af}
                            {...currentDay.afLbs}
                            decimals={0}
                            inputProps={{ 'data-testid': 'drops-total-called' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell />
                        <StyledTableCell />
                      </TableRow>
                    )}

                    {day === 'tomorrow' && (
                      <TableRow sx={{ alignItems: 'center', background: (theme) => theme.palette.blue[100] }}>
                        <StyledTableCell>Total</StyledTableCell>
                        <StyledTableCell>100%</StyledTableCell>
                        <StyledTableCell />
                        <StyledTableCell />
                        <StyledTableCell>
                          <RationInput rations={rations} {...currentDay.rationId} data-testid="drops-total-ration" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IncrementalNumberInput
                            increment={increments.af}
                            {...currentDay.afLbs}
                            decimals={0}
                            inputProps={{ 'data-testid': 'drops-total-called' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    )}

                    {Array.from({ length: dropCount }).map((_, i) => {
                      const drop = currentDay.drops[i];
                      const todayDrop = state.today.drops[i];
                      const tomorrowDrop = state.tomorrow.drops[i];
                      return (
                        <>
                          {day === 'today' && (
                            <TableRow key={i}>
                              <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                                {i + 1}
                                <DropDeleteButton drop={drop} index={i} />
                              </StyledTableCell>
                              <StyledTableCell data-testid="drop-percent">
                                {!drop ? (
                                  '-'
                                ) : drop.distributionPercent === 'one-time' ? (
                                  <em>(once)</em>
                                ) : state.distributionPercents ? (
                                  `${state.distributionPercents.value[i]?.toFixed(0) ?? '-'}%`
                                ) : typeof drop.distributionPercent === 'number' ? (
                                  `${drop.distributionPercent.toFixed(0)}%`
                                ) : null}
                              </StyledTableCell>
                              <StyledTableCell>
                                {!drop ? (
                                  '-'
                                ) : (
                                  <RationInput rations={rations} {...drop.rationId} data-testid="drop-ration" />
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {!todayDrop ? (
                                  '-'
                                ) : (
                                  <CallAmountInput
                                    increment={increments.af}
                                    {...todayDrop.lbsCalled}
                                    inputProps={{ 'data-testid': 'drop-called' }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell sx={{ whiteSpace: 'nowrap' }} data-testid="drop-fed">
                                {!todayDrop ? '-' : todayDrop.lbsFed === 0 ? <em>Not Fed</em> : `${todayDrop.lbsFed}`}
                              </StyledTableCell>
                              <StyledTableCell data-testid="drop-tomorrow">
                                {!tomorrowDrop ? (
                                  '-'
                                ) : (
                                  <ReadonlyCallWithRation
                                    value={tomorrowDrop.lbsCalled.value.afLbs}
                                    color={rations.find((r) => r.id === tomorrowDrop.rationId.value)?.color}
                                  />
                                )}
                              </StyledTableCell>
                            </TableRow>
                          )}

                          {day === 'tomorrow' && (
                            <TableRow key={i}>
                              <StyledTableCell>{i + 1}</StyledTableCell>
                              <StyledTableCell data-testid="drop-percent">
                                {!drop ? (
                                  '-'
                                ) : drop.distributionPercent === 'one-time' ? (
                                  <em>(once)</em>
                                ) : state.distributionPercents ? (
                                  `${state.distributionPercents.value[i]?.toFixed(0) ?? '-'}%`
                                ) : typeof drop.distributionPercent === 'number' ? (
                                  `${drop.distributionPercent.toFixed(0)}%`
                                ) : null}
                              </StyledTableCell>
                              <StyledTableCell data-testid="drop-today">
                                {!todayDrop ? (
                                  '-'
                                ) : (
                                  <ReadonlyCallWithRation
                                    value={todayDrop.lbsCalled.value.afLbs}
                                    color={rations.find((r) => r.id === todayDrop.rationId.value)?.color}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell data-testid="drop-fed">
                                {!todayDrop ? '-' : todayDrop.lbsFed === 0 ? <em>Not Fed</em> : `${todayDrop.lbsFed}`}
                              </StyledTableCell>

                              <StyledTableCell>
                                {!drop ? (
                                  '-'
                                ) : (
                                  <RationInput rations={rations} {...drop.rationId} data-testid="drop-ration" />
                                )}
                              </StyledTableCell>

                              <StyledTableCell>
                                {!tomorrowDrop ? (
                                  '-'
                                ) : (
                                  <CallAmountInput
                                    increment={increments.af}
                                    {...tomorrowDrop.lbsCalled}
                                    inputProps={{ 'data-testid': 'drop-called' }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <DropDeleteButton drop={drop} index={i} />
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })}

                    {currentDay.oneTimeDrops.map((oneTimeDrop, i) => (
                      <TableRow key={i}>
                        <StyledTableCell>
                          {oneTimeDrop.onDelete ? (
                            <IconButton
                              disabled={oneTimeDrop.onDelete === undefined}
                              onClick={() => oneTimeDrop.onDelete?.(i)}
                              data-testid="drop-delete-button"
                            >
                              <DeleteIcon
                                color={oneTimeDrop.onDelete === undefined ? 'disabled' : 'primary'}
                                sx={{ fontSize: 16 }}
                              />
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                        <StyledTableCell />
                        <StyledTableCell>
                          <RationInput rations={rations} {...oneTimeDrop.rationId} data-testid="drop-ration" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IncrementalNumberInput
                            increment={increments.af}
                            {...oneTimeDrop.lbsCalled}
                            decimals={0}
                            inputProps={{ 'data-testid': 'drop-called' }}
                          />
                        </StyledTableCell>
                        <StyledTableCell data-testid="drop-fed">
                          {oneTimeDrop.lbsFed === 0 ? <em>Not Fed</em> : `${oneTimeDrop.lbsFed}`}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Grid container>
              <Grid item xs={6}>
                {currentDay.onAddOneTimeDrop ? (
                  <AddDropButton
                    onAddDrop={currentDay.onAddOneTimeDrop}
                    rations={rations}
                    data-testid="add-one-time-drop-button"
                  >
                    + One Time Drop
                  </AddDropButton>
                ) : null}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {currentDay.onAddRecurringDrop ? (
                  <AddDropButton
                    onAddDrop={currentDay.onAddRecurringDrop}
                    rations={rations}
                    data-testid="add-recurring-drop-button"
                  >
                    + Recurring Drop
                  </AddDropButton>
                ) : null}
                {state.distributionPercents && (
                  <Button
                    endIcon={<LaunchIcon />}
                    variant="text"
                    onClick={() => setShowEditDropDistributionModal(true)}
                  >
                    Edit Drop Distribution
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 8 }} display="none">
            <pre>{JSON.stringify(flags)}</pre>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              <pre>Working Plan: {JSON.stringify(workingData, null, 2)}</pre>
              <pre>Actuals: {JSON.stringify(actual, null, 2)}</pre>
              <pre>UI State: {JSON.stringify(state, null, 2)}</pre>
            </div>
          </Grid>
        </Grid>
      )}

      <PenHistoryBox penId={penId} lotId={selectedPenLot?.lotId!} />

      <Dialog open={showEditDropDistributionModal} maxWidth="sm" fullWidth>
        <Box sx={{ padding: 4 }}>
          <DialogTitle title="Edit Drop Distribution" />
          <Typography mb={2}>
            Setup your daily drops. This defines the number of drops and the amount of feed in each drop for pen
          </Typography>

          <Box mb={2}>{state.distributionPercents && <PercentsInput {...state.distributionPercents} />}</Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setShowEditDropDistributionModal(false)}>Save</Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

const showSaveFailedModal = (message: string): Promise<'cancel' | 'undo-and-retry'> => {
  return new Promise((resolve) => {
    ModalManager.show(SaveFailedModal, { message, onResponse: resolve });
  });
};
const SaveFailedModal = ModalManager.create<{
  message: string;
  onResponse: (response: 'cancel' | 'undo-and-retry') => void;
}>(({ message, onResponse }) => {
  const modal = useModal();
  const isPhoneSize = useIsPhoneSize();

  const onClose: ModalProps['onClose'] = (event, reason) => {
    onResponse('cancel');
    modal.remove();
  };

  return (
    <Dialog onClose={onClose} open={modal.visible} maxWidth={'xs'} fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogContent sx={{ py: 2 }}>
          <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.error.main }} />
          <Typography variant="h2" sx={{ pb: 1 }}>
            Save Failed
          </Typography>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            data-test-id="call-plan-save-failed-cancel-button"
            variant="text"
            type="reset"
            onClick={() => {
              onResponse('cancel');
              modal.remove();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            data-test-id="call-plan-save-failed-reload-and-try-again-button"
            onClick={() => {
              onResponse('undo-and-retry');
              modal.remove();
            }}
          >
            Reload and Try Again
          </LoadingButton>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
});

const AddDropButton: React.FC<
  PropsWithChildren<{
    onAddDrop: (rationId: number) => void;
    rations: readonly { id: number; name: string; color: string }[];
    'data-testid'?: string;
  }>
> = ({ onAddDrop, rations, children, 'data-testid': dataTestId }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
  return (
    <>
      <Button variant="text" onClick={(e) => setMenuAnchor(e.currentTarget)} data-testid={dataTestId}>
        {children}
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(undefined)}
        data-testid={`${dataTestId}-menu`}
      >
        {rations.map(({ id, name, color }) => (
          <MenuItem
            data-testid={`${dataTestId}-menu-item`}
            key={id}
            value={id}
            onClick={() => {
              onAddDrop(id);
              setMenuAnchor(undefined);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
              <div style={{ display: 'block', width: '0.8em', height: '0.8em', backgroundColor: color }} />
              <div>{name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ReadonlyCallWithRation: React.FC<{ value: number; color: string | undefined }> = ({ value, color }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
      <div
        style={{
          display: 'block',
          width: '0.8em',
          height: '0.8em',
          backgroundColor: color ?? 'transparent',
        }}
      />
      <div>{value.toFixed(0)}</div>
    </div>
  );
};

const confirmSave = (saveFunc: () => Promise<SaveResult>): Promise<boolean> => {
  return new Promise((resolve) => {
    showYouMustSaveFirstDialog({ save: saveFunc, onFinish: (canceled) => resolve(!canceled) });
  });
};
type YouMustSaveFirstDialogProps = {
  save: () => Promise<SaveResult>;
  onFinish: (canceled: boolean) => void;
};
export const showYouMustSaveFirstDialog = (props: YouMustSaveFirstDialogProps) =>
  ModalManager.show(YouMustSaveFirstDialog, props);
const YouMustSaveFirstDialog = ModalManager.create<YouMustSaveFirstDialogProps>(({ save, onFinish }) => {
  const modal = useModal();
  const [saving, setSaving] = useState(false);
  const isPhoneSize = useIsPhoneSize();

  const doSave = useCallback(() => {
    (async () => {
      setSaving(true);
      try {
        // TODO: handle save failure
        const result = await save();
        setSaving(false);
        onFinish(result !== 'success');
        modal.remove();
      } catch (e) {
        setSaving(false);
        console.error(e);
      }
    })();
  }, [modal, save, onFinish]);

  const doCancel = useCallback(() => {
    modal.remove();
    onFinish(true);
  }, [modal, onFinish]);

  return (
    <Dialog onClose={doCancel} open={modal.visible} maxWidth={'xs'} fullWidth fullScreen={isPhoneSize}>
      <DialogContainer>
        <DialogContent sx={{ py: 2 }}>
          <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.yellow[400] }} />
          <Typography variant="h2" sx={{ pb: 1 }}>
            {'Save Required'}
          </Typography>
          <Typography>{'Going to tomorrow will save your changes for today.'}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <LoadingButton data-testid="call-plan-save-first-save-and-continue-button" onClick={doSave} loading={saving}>
            {'Save and Continue'}
          </LoadingButton>
          <Button data-testid="call-plan-save-first-cancel-button" variant="text" type="reset" onClick={doCancel}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
});

const DropDeleteButton = ({ drop, index }: { drop: CallFeedDropUiState; index: number }) => {
  if (!drop?.onDelete) {
    return null;
  }
  return (
    <IconButton onClick={() => drop.onDelete?.(index)} data-testid="drop-delete-button">
      <DeleteIcon color="primary" sx={{ fontSize: 16 }} />
    </IconButton>
  );
};
