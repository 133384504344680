import { PlanCallMethod, PlanCallUnit } from '@/web-types';
import {
  PenCallByDistributionPlan,
  PenCallAsFedByDropPlan,
  CallFeedDropsMap,
  CallFeedDropIdAndActual,
} from '../callPlan';
import { normalizeDistribution, planUnitConvert } from '../callPlan/functions';
import { sum } from 'lodash';
import { calcRecurringForByDistribution, CalcRecurringForByDistributionArgs } from './byDistributionFunctions';
import { RationToDryMatterRatio } from '@/common/models/ration';

// Call Feed Plans are not stored directly in the database, but rather are encoded into the drops table
// This module is responsible for converting between the two representations
// 1. A list of drops which are either recurring or one-time
// 2. A plan structure that is used by the call feed user interface

/** A recurring drop from the database */
export type DbRecurringDrop = {
  id: number | undefined;
  /** Amount in plan units */
  amount: number | null;
  /** Override in plan units */
  override: number | null;
  rationId: number;
};

/** A one-time drop from the database */
export type DbOneTimeDrop = {
  id: number | undefined; // the drop id, undefined until it is saved
  calledLbs: number;
  rationId: number;
};

export type PlanDayDbDrops = {
  recurring: readonly DbRecurringDrop[];
  oneTime: readonly DbOneTimeDrop[];
};

/** An intermediate structure that separates the recurring and one-time drops for today and tomorrow */
export type PlanDbDrops = {
  callUnit: PlanCallUnit;
  today: PlanDayDbDrops;
  tomorrow: readonly DbRecurringDrop[];
};

/** An intermediate structure that separates the recurring and one-time drops for yesterday */
export type PlanDbDropsYesterday = {
  yesterday: PlanDayDbDrops;
};

/** Return the first parameter if it is different than the second parameter. Else return undefined. */
const ifPresentAndDifferent: <T>(value: T | undefined, comparedTo: T) => T | undefined = (value, comparedTo) => {
  if (!value) return undefined;
  if (isSame(value, comparedTo)) return undefined;
  return value;
};

const isSame: <T>(a: T, b: T) => boolean = (a, b) => {
  if (Array.isArray(a)) {
    return Array.isArray(b) && a.length === b.length && a.every((v, i) => isSame(v, b[i]));
  }
  if (typeof a === 'number') {
    // allow a less exact match for floating point numbers
    return typeof b === 'number' && (a === b || Math.abs(a - b) < Math.pow(2, -50));
  }
  return a === b;
};

/**
 * Return a by-distribution plan structure given drops from the database and a call unit
 * The call unit is the call unit of the plan and all of the drops must first be converted into this unit before calling this function
 */
export const byDistributionPlanFromDb: (planDrops: PlanDbDrops) => PenCallByDistributionPlan = (planDrops) => {
  const { callUnit, today: dropsToday, tomorrow: dropsTomorrow } = planDrops;

  const recurringToday = dropsToday.recurring;
  const amountsToday = recurringToday.map((d) => d.override ?? d.amount).filter((a) => a !== null);
  const amountsTodayWithoutOverrides = recurringToday.map((d) => d.amount).filter((a) => a !== null);

  const recurringTomorrow = dropsTomorrow.length ? dropsTomorrow : undefined;
  const amountsTomorrow = recurringTomorrow?.map((d) => d.override ?? d.amount).filter((a) => a !== null);
  const amountsTomorrowWithoutOverrides = recurringTomorrow?.length
    ? recurringTomorrow?.map((d) => d.amount).filter((a) => a !== null)
    : undefined;

  const callAmount = sum(amountsToday);
  const callAmountTomorrow = amountsTomorrow ? sum(amountsTomorrow) : undefined;
  const callAmountAfterToday = ifPresentAndDifferent(callAmountTomorrow, callAmount);

  const distribution = normalizeDistribution(amountsTomorrowWithoutOverrides ?? amountsTodayWithoutOverrides);
  const distributionToday = normalizeDistribution(amountsToday);
  const distributionTomorrow = amountsTomorrow ? normalizeDistribution(amountsTomorrow) : undefined;
  const distributionTodayOverride = ifPresentAndDifferent(distributionToday, distribution);
  const distributionTomorrowOverride = ifPresentAndDifferent(distributionTomorrow, distribution);

  const rationIds = recurringToday.map((d) => d.rationId);
  const rationIdsTomorrow = recurringTomorrow?.map((d) => d.rationId);
  const rationIdsAfterToday = ifPresentAndDifferent(rationIdsTomorrow, rationIds);

  const oneTimeToday = dropsToday.oneTime;

  const oneTimeDropsToday = oneTimeToday.map((otd) => ({
    id: otd.id,
    rationId: otd.rationId,
    afLbs: otd.calledLbs,
  }));
  const distributedPlan: PenCallByDistributionPlan = {
    method: PlanCallMethod.ByDistribution,
    distribution,
    ...(distributionTodayOverride ? { distributionTodayOverride } : undefined),
    ...(distributionTomorrowOverride ? { distributionTomorrowOverride } : undefined),
    callUnit,
    callAmount,
    ...(callAmountAfterToday ? { callAmountAfterToday } : undefined),
    rationIds,
    ...(rationIdsAfterToday ? { rationIdsAfterToday } : undefined),
    oneTimeDropsToday,
    adjustLastForActualFed: false,
  };
  return distributedPlan;
};

/**
 * Return a by-drop plan structure given drops from the database
 * the call unit of the plan will be as-fed lbs
 * the drops must first be converted into this unit before calling this function
 */
export const byDropPlanFromDb: (dbDrops: PlanDbDrops) => PenCallAsFedByDropPlan = (dbDrops) => {
  const { callUnit, today: dropsToday, tomorrow: dropsTomorrow } = dbDrops;

  // TODO: this check is necessary because PenCallAsFedByDropPlan requires this unit
  //       but if we remove that requirement (and simply enforce it elsewhere) everything might become much simpler
  //       if so, remove this below. If not, remove this todo
  if (callUnit !== PlanCallUnit.AsFedLbs) {
    throw new Error('callUnit must be PlanCallUnit.AsFedLbs');
  }

  const recurringToday = dropsToday.recurring;
  const oneTimeToday = dropsToday.oneTime;
  const recurringTomorrow = dropsTomorrow.length ? dropsTomorrow : undefined;
  const rationIds = recurringToday.map((d) => d.rationId);
  const rationIdsAfterToday = recurringTomorrow?.map((d) => d.rationId);
  const oneTimeDropsToday = oneTimeToday.map((otd) => ({
    id: otd.id,
    rationId: otd.rationId,
    afLbs: otd.calledLbs,
  }));
  const dropCallAmounts = recurringToday.map((d) => d.amount).filter((a) => a !== null);
  const mapIndexes = recurringToday.map((_, i) => i);
  const dropCallAmountsAfterToday = recurringTomorrow?.map((d) => d.amount).filter((a) => a !== null);
  const mapIndexesAfterToday = recurringTomorrow?.map((_, i) => i);
  const byDropPlan: PenCallAsFedByDropPlan = {
    method: PlanCallMethod.ByDrop,
    rationIds,
    ...(rationIdsAfterToday ? { rationIdsAfterToday } : undefined),
    dropCallAmounts,
    ...(dropCallAmountsAfterToday ? { dropCallAmountsAfterToday } : undefined),
    mapIndexes,
    mapIndexesAfterToday,
    callUnit,
    oneTimeDropsToday,
    adjustLastForActualFed: false,
  };
  return byDropPlan;
};

type ActualWithUnit = CallFeedDropIdAndActual['actual'] & { called: number; calledUnit: PlanCallUnit };

function actualWithUnits(
  actual: NonNullable<CallFeedDropIdAndActual['actual']>,
  withUnit: PlanCallUnit,
  rationIdToDmRatio: RationToDryMatterRatio
): ActualWithUnit {
  return {
    ...actual,
    called: planUnitConvert(
      actual.afLbsCalled,
      PlanCallUnit.AsFedLbs,
      withUnit,
      actual.head,
      rationIdToDmRatio(actual.rationId)
    ),
    calledUnit: withUnit,
  };
}

/**
 * Returns database drops given a by-distribution plan structure
 */
export const byDistributionPlanToDb = (
  plan: PenCallByDistributionPlan,
  dropMap: CallFeedDropsMap,
  rationIdToDmRatio: RationToDryMatterRatio
): PlanDbDrops => {
  const {
    distribution,
    distributionTodayOverride,
    distributionTomorrowOverride,
    callAmount,
    callAmountAfterToday,
    rationIds,
    rationIdsAfterToday,
    oneTimeDropsToday,
    callUnit,
    adjustLastForActualFed,
  } = plan;

  const dropMapToday: CalcRecurringForByDistributionArgs['dropMap'] = dropMap.today.map((d) => ({
    dropId: d.dropId,
    actual: d.actual ? actualWithUnits(d.actual, callUnit, rationIdToDmRatio) : undefined,
  }));

  // if there's an override today, calculate this separately
  const recurringCallsToday = calcRecurringForByDistribution({
    planDistribution: distribution,
    planPenAmount: callAmount,
    planRationIds: rationIds,
    dropMap: dropMapToday,
    adjustLastForActualFed,
  });

  const recurringOverridesToday = distributionTodayOverride
    ? calcRecurringForByDistribution({
        planDistribution: distributionTodayOverride,
        planPenAmount: callAmount,
        planRationIds: rationIds,
        dropMap: dropMapToday,
        adjustLastForActualFed,
      })
    : undefined;

  const recurringDropsToday: DbRecurringDrop[] = (recurringOverridesToday ?? recurringCallsToday).map((call, i) => {
    const today = recurringCallsToday[i];
    const override = recurringOverridesToday?.[i];
    return {
      id: call.drop?.dropId,
      rationId: call.rationId,
      amount: today?.amountInPlanUnits ?? null,
      override: override?.amountInPlanUnits ?? null,
    };
  });

  const dropMapTomorrow: CalcRecurringForByDistributionArgs['dropMap'] = dropMap.tomorrow.map((d) => ({
    dropId: d.dropId,
    actual: d.actual ? actualWithUnits(d.actual, callUnit, rationIdToDmRatio) : undefined,
  }));

  const haveTomorrow =
    plan.callAmountAfterToday != null || plan.rationIdsAfterToday != null || plan.distributionTomorrowOverride != null;

  // if there's an override tomorrow, calculate this separately
  const recurringCallsTomorrow = calcRecurringForByDistribution({
    planDistribution: plan.distribution,
    planPenAmount: callAmountAfterToday ?? callAmount,
    planRationIds: rationIdsAfterToday ?? rationIds,
    dropMap: dropMapTomorrow,
    adjustLastForActualFed,
  });

  const recurringOverridesTomorrow = plan.distributionTomorrowOverride
    ? calcRecurringForByDistribution({
        planDistribution: plan.distributionTomorrowOverride,
        planPenAmount: callAmountAfterToday ?? callAmount,
        planRationIds: rationIdsAfterToday ?? rationIds,
        dropMap: dropMapTomorrow,
        adjustLastForActualFed,
      })
    : undefined;

  const recurringDropsTomorrow: DbRecurringDrop[] = (recurringOverridesTomorrow ?? recurringCallsTomorrow).map(
    (call, i) => {
      const tomorrow = recurringCallsTomorrow[i];
      const override = recurringOverridesTomorrow?.[i];
      return {
        id: call.drop?.dropId,
        rationId: call.rationId,
        amount: tomorrow?.amountInPlanUnits ?? null,
        override: override?.amountInPlanUnits ?? null,
      };
    }
  );

  const oneTimeToday = (oneTimeDropsToday ?? []).map((otd) => {
    const otdrop: DbOneTimeDrop = {
      id: otd.id,
      rationId: otd.rationId,
      calledLbs: otd.afLbs,
    };
    return otdrop;
  });

  return {
    callUnit,
    today: {
      recurring: recurringDropsToday,
      oneTime: oneTimeToday,
    },
    tomorrow: haveTomorrow ? recurringDropsTomorrow : [],
  };
};

/**
 * Returns database drops given a by-drop plan structure
 */
export const byDropPlanToDb = (plan: PenCallAsFedByDropPlan, dropMap: CallFeedDropsMap): PlanDbDrops => {
  const {
    rationIds,
    rationIdsAfterToday,
    dropCallAmounts,
    dropCallAmountsAfterToday,
    mapIndexes,
    mapIndexesAfterToday,
    callUnit,
    oneTimeDropsToday,
  } = plan;

  // by-drop plans can have specific drops deleted
  // if this occurs, the plan will have a map to the new indexes
  //const mapToday = plan.indexMapToday ? plan.indexMapToday.map((i) => dropMap.today[i]) : dropMap.today;
  //const dropMapTomorrow = plan.indexMapTomorrow ? plan.indexMapTomorrow.map((i) => dropMap.tomorrow[i]) : dropMap.tomorrow;
  const mapToday = dropMap.today;
  const mapTomorrow = dropMap.tomorrow;

  // 1. Recurring drops for today
  const recurringDropsToday: DbRecurringDrop[] = rationIds.map((rationId, i) => {
    const id = mapToday[mapIndexes[i]]?.dropId;
    return {
      id,
      rationId,
      amount: dropCallAmounts[i],
      override: null,
    };
  });

  // 2. Optional recurring drops for tomorrow
  const recurringDropsTomorrow: DbRecurringDrop[] =
    dropCallAmountsAfterToday && rationIdsAfterToday
      ? rationIdsAfterToday.map((rationId, i) => {
          const index = mapIndexesAfterToday?.[i];
          const id = index ? mapTomorrow[index]?.dropId : undefined;
          return {
            id,
            rationId,
            amount: dropCallAmountsAfterToday[i],
            override: null,
          };
        })
      : [];

  // 3. One-time drops for today
  const oneTimeToday: DbOneTimeDrop[] = (oneTimeDropsToday ?? []).map((otd) => {
    const otdrop: DbOneTimeDrop = {
      id: otd.id,
      rationId: otd.rationId,
      calledLbs: otd.afLbs,
    };
    return otdrop;
  });

  // 4. Combine
  return {
    callUnit,
    today: {
      recurring: recurringDropsToday,
      oneTime: oneTimeToday,
    },
    tomorrow: recurringDropsTomorrow,
  };
};
