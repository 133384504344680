import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { SaveResult } from './useCallFeedPlanDropsAndActuals';

type WorkingState<T> = {
  // the current data that the user is modifying
  working?: T;
  // the saved data that was saved when the user started modifying
  workingBase?: T;
  // the version of the data that is saving
  saving?: T;
};

/**
 * Implement the "working" state of savable data.
 * Given data that can be saved to a backend, this function provides a working version of it and other useful tools to deal with dirty, saving, and collision
 * @param saved the data that is currently persisted to the backend
 * @param save a function that will persist data to the backend
 * @param isEqual an optional function to determine if two states are equal
 * @returns
 */
export function useWorking<T>(
  saved: T,
  save: (data: T) => Promise<SaveResult>,
  isEqual: (a: T, b: T) => boolean = statesEqual
): [
  T,
  (fn: (prev: T) => T) => void,
  { dirty: boolean; collision: boolean; saving: boolean },
  () => Promise<SaveResult>,
] {
  const [state, setState] = useState<WorkingState<T>>({});
  const { working, workingBase: base, saving } = state;
  const dirty = useMemo(() => !!saved && !!working && !isEqual(saved, working), [saved, working, isEqual]);
  // if there is a collision (someone else has edited this while we still have unsaved changes)
  // ideally we'd merge the user's changes with the other changes, but if this is impractical (or impossible, which it probably is),
  // we should at least show this to the user, and possibly alert the user on an attempted save
  const collision = useMemo(
    () => !!base && dirty && !saving && !isEqual(saved, base),
    [saved, base, dirty, saving, isEqual]
  );
  const flags = useMemo(() => ({ dirty, collision, saving: Boolean(saving) }), [dirty, collision, saving]);
  const saveWorking: () => Promise<SaveResult> = useMemo(() => {
    // if there have been no changes, then there is nothing to save
    if (!working) return () => Promise.resolve('success' as const);
    const saving = working;
    return async () => {
      setState((prev) => ({ ...prev, saving }));
      const status = await save(saving);
      // TODO: confirm this is correct. There's a possible race condition here
      // We really only want to do this update once we've confirmed that the `saved` from the props is the same as the `working` that we saved
      // this might require a useEffect
      setState((prev) => ({ working: prev.working, workingBase: saving }));
      return status;
    };
  }, [working, save]);
  const setWorking = useCallback(
    (fn: (data: T) => T) =>
      setState((prev) => {
        const prevWorking = prev.working ?? saved;
        const working = fn(prevWorking);
        return { ...prev, working, workingBase: prev.workingBase ?? saved };
      }),
    [setState, saved]
  );
  return [working ?? saved, setWorking, flags, saveWorking];
}

const statesEqual = <T>(a: T, b: T) => isEqual(a, b);
