import { default as MuiSelect } from '@mui/material/Select';
import { useMemo } from 'react';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { default as MenuItem } from '@mui/material/MenuItem';
import { Input } from '@/components/types';

export type RationValueInput = Input<number | undefined | 'multiple', number>;
type OnChange = RationValueInput['onChange'];

type CompatibleOnChange = SelectInputProps<number | 'none' | 'multiple'>['onChange'];

/** Get an html-onchange-compatible handler for a number on change */
function compatibleOnChangeFor(onChange: OnChange, params: unknown | undefined): CompatibleOnChange {
  return (ev, el) => {
    const controlValue = ev.target.value;
    if (typeof controlValue === 'number') {
      onChange(controlValue, params);
    }
  };
}

type RationInputProps = RationValueInput & {
  rations: readonly { id: number; name: string; color: string }[];
  'data-testid'?: string;
};
export const RationInput: React.FC<RationInputProps> = ({
  params,
  rations,
  value: valueRaw,
  onChange,
  disabled,
  ...inputProps
}) => {
  const value = valueRaw ?? 'none';
  const compatibleOnChange = useMemo(() => compatibleOnChangeFor(onChange, params), [onChange, params]);
  const invalidValue = useMemo(
    () => value != 'none' && value !== 'multiple' && !rations.some((r) => r.id === value),
    [value, rations]
  );
  const dataTestId = inputProps?.['data-testid'];
  return (
    <MuiSelect
      disabled={disabled}
      value={value ?? 'none'}
      onChange={compatibleOnChange}
      sx={{ width: '100%' }}
      {...inputProps}
    >
      {value === 'multiple' && (
        <MenuItem value="multiple">
          <em style={{ color: 'gray' }}>(mixed)</em>
        </MenuItem>
      )}
      {value === 'none' && (
        <MenuItem value={'none'}>
          <em>(none)</em>
        </MenuItem>
      )}
      {rations.map(({ id, name, color }) => (
        <MenuItem key={id} value={id} data-testid={dataTestId ? `${dataTestId}-menu-item` : undefined}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
            }}
          >
            <div style={{ display: 'block', minWidth: '0.8em', height: '0.8em', backgroundColor: color }} />
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
            </div>
          </div>
        </MenuItem>
      ))}
      {/* Make sure the actual value is always an option in the list */}
      {invalidValue && (
        <MenuItem key={value} value={value ?? 'none'}>
          Unknown [{value}]
        </MenuItem>
      )}
    </MuiSelect>
  );
};
