import { getRationCurrentRevisionToDryMatterRatio, RationToDryMatterRatio } from '@/common/models/ration';
import { Ration, useGetRationsQuery } from '@/web-types';
import gql from 'graphql-tag';
import { useMemo } from 'react';

gql`
  query GetRations {
    getRations {
      id
      name
      position
      currentRevision
      color
      ingredients {
        id
        rationId
        rationRevision
        percentOfRationAsFed
        ingredient {
          id
          name
          current {
            percentDry
          }
        }
      }
    }
  }
`;

// A sepecial simplified type for listing available ration options
export type RationOption = {
  id: number;
  name: string;
  color: string;
};

export function useRations(): {
  rations: readonly RationOption[] | undefined;
  rationIdToDmRatio: RationToDryMatterRatio | undefined;
} {
  const [{ data, fetching }] = useGetRationsQuery();
  const rations = data?.getRations as Ration[];
  const rationIdToDmRatio = useMemo(() => {
    if (!rations) return undefined;
    const getDmRatio = getRationCurrentRevisionToDryMatterRatio(rations);
    // we return a version that will handle an undefined or 0 value sanely
    // this way if the user selects "none" this remains callable without throwing and returns a value that remains consistent
    return (rationId: number | undefined) => (!rationId ? 1.0 : getDmRatio(rationId));
  }, [rations]);
  const rationOptions = useMemo(() => rations?.map((r) => ({ id: r.id, name: r.name, color: r.color })), [rations]);
  return { rations: rationOptions, rationIdToDmRatio };
}
