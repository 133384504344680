import { Button, styled } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';

const StyledButton = styled(Button)({
  px: 1,
  py: 0,
  height: 30,
  fontWeight: 400,
  fontSize: 12,
  boxShadow: 'none',
  borderRadius: 5,
  border: '1px solid #B3C3CE !important',
  '&:hover': { background: '#B3C3CE !important', boxShadow: 'none !important' },
});

export const TodayTomorrowStack = ({
  value,
  onChange,
}: {
  value: 'today' | 'tomorrow';
  onChange: (value: 'today' | 'tomorrow') => void;
}) => {
  return (
    <ButtonGroup>
      <StyledButton
        color="inherit"
        variant={value === 'today' ? 'contained' : 'outlined'}
        onClick={() => onChange('today')}
        data-testid="today-button"
      >
        Today
      </StyledButton>
      <StyledButton
        color="inherit"
        variant={value === 'tomorrow' ? 'contained' : 'outlined'}
        onClick={() => onChange('tomorrow')}
        data-testid="tomorrow-button"
      >
        Tomorrow
      </StyledButton>
    </ButtonGroup>
  );
};
