import * as DistributedFunctions from './byDistributionPlanFunctions';
import * as AfPerDropFunctions from './asFedByDropPlanFunctions';
import { PlanCallMethod, PlanCallUnit } from '@/web-types';
import { RationToDryMatterRatio } from '@/common/models/ration';
import { localdate } from '@/common/models/types';
import { BunkScore } from '@prisma/client';
import { isPresent } from 'ts-is-present';

// There are two different behavior types for today/tomorrow:
//  1. distributions are overridden only for a day to handle manual adjustments and skew due to adjustments after drops are fed
//  2. rations, and feed amounts are simply "the same until changed" and so the "last" value is the persistent value

export type PenCallPlan = PenCallByDistributionPlan | PenCallAsFedByDropPlan;

/** This is a plan that is based on a distribution of a daily amount  */
export type PenCallByDistributionPlan<TUnit extends PlanCallUnit = PlanCallUnit> = {
  method: PlanCallMethod.ByDistribution;
  /** this is the distribution for this pen unless overridden  */
  distribution: readonly number[];
  /** this is the distribution for today only if specified  */
  distributionTodayOverride?: readonly number[];
  /** this is the distribution for tomorrow only if specified  */
  distributionTomorrowOverride?: readonly number[];
  /** this is the unit of the "callAmount..." values  */
  callUnit: TUnit;
  /** this is the amount called for today (in callUnit), and beyond unless tomorrow is specified  */
  callAmount: number;
  /** this is the dmi per head for tomorrow and beyond if specified  */
  callAmountAfterToday?: number;
  /** these are the rations today, and beyond unless tomorrow is specified  */
  rationIds: readonly number[];
  /** these are the rations for tomorrow and beyond if specified  */
  rationIdsAfterToday?: readonly number[];
  /** one-time drops (refeeds) are manually entered by the user and are not part of the repeating plan  */
  oneTimeDropsToday: readonly OneTimeDrop[];
  /** whether to compensate in last drop for variance in actual fed  */
  adjustLastForActualFed: boolean;
};

/** This is a plan that specifies an absolute amount for each drop  */
export type PenCallAsFedByDropPlan = {
  method: PlanCallMethod.ByDrop;
  /** these are the rations today, and beyond unless tomorrow is specified  */
  rationIds: readonly number[];
  /** these are the rations for tomorrow and beyond if specified  */
  rationIdsAfterToday?: readonly number[];
  /** this is the as fed per drop for today, and beyond unless tomorrow is specified  */
  dropCallAmounts: readonly number[];
  /** this is the as fed per drop for tomorrow and beyond if specified  */
  dropCallAmountsAfterToday?: readonly number[];
  /**
   * this maps drops in the plan to drops in the `dropsMap`
   * it is always [0, 1, 2, ...] unless drops are deleted
   */
  // TODO (Aurel) wouldn't it be easier to store objects?
  mapIndexes: readonly number[];
  /** this is for tomorrow and beyond if specified  */
  mapIndexesAfterToday?: readonly number[];
  /**
   * this is the unit of the "dropCallAmounts..." values.
   * For by-drop plans, as-fed-lbs is the only supported unit. Others are conceivable but not expected to be useful.
   */
  callUnit: PlanCallUnit.AsFedLbs;
  /** one-time drops (refeeds) are manually entered by the user and are not part of the repeating plan  */
  oneTimeDropsToday: readonly OneTimeDrop[];
  /** whether to compensate in last drop for variance in actual fed  */
  adjustLastForActualFed: boolean;
};

export type OneTimeDrop = {
  id: number | undefined; // undefined until it is saved
  rationId: number;
  afLbs: number;
};

// The following are plans that have been resolved for a given day

export type PenCallPlanDay = PenCallByDistributionPlanDay | PenCallAsFedByDropPlanDay;

export type PenCallByDistributionPlanDay = {
  method: PlanCallMethod.ByDistribution;
  distribution: readonly number[];
  callAmount: number;
  callUnit: PlanCallUnit;
  rationIds: readonly number[];
  oneTimeDrops: readonly OneTimeDrop[];
};

export type PenCallAsFedByDropPlanDay = {
  method: PlanCallMethod.ByDrop;
  rationIds: readonly number[];
  dropCallAmounts: readonly number[];
  callUnit: PlanCallUnit.AsFedLbs; // For by-drop plans, as-fed-lbs is the only supported unit. Others are conceivable but not expected to be useful
  oneTimeDrops: readonly OneTimeDrop[];
  mapIndexes: readonly number[];
};

/**
 *  This is a record corresponding to the "day drop"
 * The dropId here will be different every day for the same "plan drop"
 * This serves as a mapping between the plan and the drops including the actuals for the drop
 * In Batch feeding (todo), this is the parent drop only with actual data being an aggregate of the child drops
 */
export type CallFeedDropIdAndActual = {
  dropId: number; // the drop id
  actual?: {
    rationId: number;
    head: number;
    afLbsCalled: number;
    afLbsFed: number;
  };
};

export type CallFeedDropsMap = {
  today: readonly CallFeedDropIdAndActual[];
  tomorrow: readonly CallFeedDropIdAndActual[];
};

export type CallFeedDropsMapYesterday = {
  yesterday: readonly CallFeedDropIdAndActual[];
};

export type PenFeedHistory = {
  date: localdate;
  bunkScore: BunkScore | undefined;
  head: number;
  afLbsCalled: number;
  afLbsFed: number;
};

export type PenState = {
  currentHead: number;
  rationIdToDmRatio: RationToDryMatterRatio;
  dropsMap: CallFeedDropsMap;
  defaultRationId: number;
};

export type Day = 'today' | 'tomorrow';

export const Empty: readonly never[] = [];
// used for tomorrow which never has actuals
export const EmptyActual: CallFeedDropsMap = { today: Empty, tomorrow: Empty };

export const rationOrLast = (rationIds: readonly number[], i: number): number => {
  return rationIds[i] ?? rationIds[rationIds.length - 1]!;
};

// Represents the amount of a call as a total amount fed, plus information on how to convert a total as fed back to a plan amount
// This is necessary because the user edits the total amount fed for drops, but we must store the plan amount
// So we must take their edited value later and convert it to the equivalent plan amount
// So we use a `y = m(x + c) + b` formula for the conversion where y = total and x = plan
// For dmi per head, `m` will generally be head / dry-matter-ratio
// For as-fed per drop, m will generally be 1.
// b and c are 0 unless a number has been added (e.g. in the case of compensation of the last drop for variance)
export type CallAmount = {
  // total as-fed lbs for this call
  afLbs: number;
  // total = (plan * (m + c)) + b
  // plan = ((total - b) / m) - c
  toPlan: { m: number; b: number; c: number };
};

export function toPlanAmount(amount: CallAmount): number {
  const { m, b, c } = amount.toPlan;
  const x = amount.afLbs - b;
  if (x === 0) return 0;
  return x / m - c;
}

// TODO: the following typescript voodoo is complicated, see about simplifying

// These are the functions that are specific to a particular call method
export type PenCallPlanFunctions<TPlan extends PenCallPlan = PenCallPlan> = {
  resolveDay(plan: TPlan, day: Day): PenCallPlanDay;

  calcCalls(
    plan: TPlan,
    state: PenState,
    day: Day
  ): readonly { dropId: number | undefined; rationId: number; head: number; amount: CallAmount }[];

  setDistributionPercents(plan: TPlan, value: readonly number[], defaultRationId: number): TPlan;
  setDmiPerHead(plan: TPlan, state: PenState, day: Day, value: number): TPlan;
  setAfPerHead(plan: TPlan, state: PenState, day: Day, afLbsPerHead: number): TPlan;
  setAfTotal(plan: TPlan, state: PenState, day: Day, afLbsTotal: number): TPlan;

  setDropAmount(plan: TPlan, state: PenState, day: Day, dropIndex: number, dropAsFedLbs: CallAmount): TPlan;

  deleteRecurringDrop(plan: TPlan, day: Day, dropIndex: number): TPlan;
  addRecurringDrop(plan: TPlan, day: Day, rationId?: number): TPlan;
};

// This maps the functions from the plan-type-specific modules by the plan type
// which is used by "callForPlanType" to map a call to the correct function according to the type of the plan argument
const functionsByMethod = {
  [PlanCallMethod.ByDistribution]: DistributedFunctions,
  [PlanCallMethod.ByDrop]: AfPerDropFunctions,
};

type Tail<T extends any[]> = T extends [any, ...infer U] ? U : never;

// this calls the plan-type-specific function named by `fn` for the plan type of `plan`
function callForPlanType<TPlan extends PenCallPlan, Fn extends keyof PenCallPlanFunctions<TPlan>>(
  fn: Fn,
  plan: TPlan,
  ...args: Tail<Parameters<PenCallPlanFunctions[Fn]>>
) {
  const f = functionsByMethod[plan.method][fn];
  if (!f) throw new Error(`No functions for ${plan.method}.${fn}`);
  // this any is required because Parameters<T> doesn't return a tuple type
  return (f as any)(plan, ...args) as ReturnType<PenCallPlanFunctions[Fn]>;
}

// this returns a function that can take a plan of any type and will call the correct plan-type-specific function
function planTypeSpecific<Fn extends keyof PenCallPlanFunctions>(fn: Fn) {
  return <TPlan extends PenCallPlan>(plan: TPlan, ...args: Tail<Parameters<PenCallPlanFunctions<TPlan>[Fn]>>) => {
    return callForPlanType<TPlan, Fn>(fn, plan, ...args) as ReturnType<PenCallPlanFunctions<TPlan>[Fn]>;
  };
}

// The following functions have plan-type-specific implementations

/** Resolves the "after today" and "day overrides" of a plan to return the plan for the specified day */
export const resolveDay = planTypeSpecific('resolveDay');

/** Calculate the calls for a plan for a given day */
export const calcCalls = planTypeSpecific('calcCalls');

/** Returns the plan but with the specified distribution percents configured */
export const setDistributionPercents = planTypeSpecific('setDistributionPercents');

/** Returns the plan but configured so that the dmi per head comes out to the specified value */
export const setDmiPerHead = planTypeSpecific('setDmiPerHead');

/** Returns the plan but configured so that the as fed per head comes out to the specified value */
export const setAfPerHead = planTypeSpecific('setAfPerHead');

/** Returns the plan but configured so that the as fed total comes out to the specified value */
export const setAfTotal = planTypeSpecific('setAfTotal');

/** Returns the plan but configured so that a specified drop comes out to the specified amount */
export const setDropAmount = planTypeSpecific('setDropAmount');

export const deleteRecurringDrop = planTypeSpecific('deleteRecurringDrop');

export const addRecurringDrop = planTypeSpecific('addRecurringDrop');

// The following functions work for any plan type

/** Returns the plan but configured so that the specified drop has the specified ration */
export function setDropRation<T extends PenCallPlan>(plan: T, day: Day, index: number, value: number): T {
  const before = day === 'today' ? plan.rationIds : (plan.rationIdsAfterToday ?? plan.rationIds);
  const after = before.slice();
  after[index] = value;
  return { ...plan, [day === 'today' ? 'rationIds' : 'rationIdsAfterToday']: after.filter(isPresent) };
}

/** Returns the plan but configured so that all drops have the specified ration */
export function setPenRation<T extends PenCallPlan>(plan: T, day: Day, value: number): T {
  return { ...plan, [day === 'today' ? 'rationIds' : 'rationIdsAfterToday']: plan.rationIds.map(() => value) };
}

/** Returns the plan with the specified one-time drop (refeed) added */
export function addOneTimeDrop<T extends PenCallPlan>(plan: T, rationId: number, afLbs: number = 0): T {
  return { ...plan, oneTimeDropsToday: [...(plan.oneTimeDropsToday ?? []), { id: undefined, rationId, afLbs }] };
}

/** Returns the plan with the specified one-time drop (refeed) removed */
export function deleteOneTimeDrop<T extends PenCallPlan>(plan: T, index: number): T {
  return { ...plan, oneTimeDropsToday: plan.oneTimeDropsToday?.filter((_, i) => i !== index) };
}

/** Returns the plan with the specified one-time drop (refeed) changed to the specified ration */
export function setOneTimeDropRation<T extends PenCallPlan>(plan: T, index: number, value: number): T {
  return {
    ...plan,
    oneTimeDropsToday: plan.oneTimeDropsToday?.map((r, i) => (i === index ? { ...r, rationId: value } : r)),
  };
}

/** Returns the plan with the specified one-time drop (refeed) changed to the specified amount */
export function setOneTimeDropAmount<T extends PenCallPlan>(plan: T, index: number, value: number): T {
  return {
    ...plan,
    oneTimeDropsToday: plan.oneTimeDropsToday?.map((r, i) => (i === index ? { ...r, afLbs: value } : r)),
  };
}
