import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { SelectPicker } from '@/components/SelectPicker/SelectPicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { gql } from 'urql';
import { usePenChangeStackQuery } from '@/web-types';

gql`
  query PenChangeStack {
    getPens(withDeleted: false) {
      id
      name
      position
      numHeads
    }
  }
`;

export const PenChangeStack = ({ penId, onChangePen }: { penId: number; onChangePen: (penId: number) => void }) => {
  const [{ fetching, data }] = usePenChangeStackQuery();
  const pens = useMemo(() => data?.getPens ?? [], [data]);

  const isPhoneSize = useIsPhoneSize();
  const selectedPenIndex = useMemo(() => {
    return pens.findIndex((pen) => pen.id === penId)!;
  }, [penId, pens]);

  const selectedPen = useMemo(() => {
    return pens.find((pen) => pen.id === penId)!;
  }, [penId, pens]);

  const changeToNextPen = useCallback(() => {
    const nextIndex = (selectedPenIndex + 1) % pens.length;
    if (pens[nextIndex]) onChangePen(pens[nextIndex].id);
  }, [onChangePen, pens, selectedPenIndex]);

  const changeToPreviousPen = useCallback(() => {
    const nextIndex = (selectedPenIndex - 1 + pens.length) % pens.length;
    if (pens[nextIndex]) onChangePen(pens[nextIndex].id);
  }, [onChangePen, pens, selectedPenIndex]);

  if (fetching) {
    return '...';
  }

  return (
    <Box flex={1} display="flex" alignItems="center">
      <SelectPicker
        caretPosition="left"
        items={pens.map((pen) => ({ label: pen.name, onClick: () => onChangePen(pen.id) }))}
        sx={{ mr: 2, fontWeight: 600, fontSize: 17 }}
        text={selectedPen?.name}
      />
      <Typography sx={{ fontSize: 14, color: 'secondary.main' }}>
        Head: <b>{selectedPen?.numHeads}</b>
      </Typography>

      <Box
        display={{ xs: 'none', md: 'flex' }}
        alignItems="center"
        position={{ xs: 'static', lg: 'absolute' }}
        left="50%"
        width={{ xs: 'auto', lg: 330 }}
        marginLeft={{ xs: 0, lg: '-115px' }}
        justifyContent="center"
      >
        <Button
          variant="text"
          disabled={selectedPenIndex === 0}
          startIcon={<NavigateBeforeIcon fontSize="small" />}
          onClick={() => changeToPreviousPen()}
          sx={{ color: 'customGrey.main', fontWeight: 500, fontSize: 13 }}
          data-testid="previous-pen-button"
        >
          {isPhoneSize ? '' : 'Previous Pen'}
        </Button>
        <Button
          variant="text"
          disabled={selectedPenIndex === pens.length - 1}
          endIcon={<NavigateNextIcon fontSize="small" />}
          onClick={() => changeToNextPen()}
          sx={{ color: 'customGrey.main', fontWeight: 500, fontSize: 13 }}
          data-testid="next-pen-button"
        >
          {isPhoneSize ? '' : 'Next Pen'}
        </Button>
      </Box>
    </Box>
  );
};
