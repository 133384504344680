import { BunkScoreValue, PlanCallMethod, PlanCallUnit } from '@/web-types';
import { useMemo } from 'react';
import { PenCallPlan, CallFeedDropsMap, CallFeedDropIdAndActual } from '../callPlan';
import {
  byDistributionPlanFromDb,
  byDropPlanFromDb,
  byDistributionPlanToDb,
  byDropPlanToDb,
  PlanDbDrops,
  PlanDayDbDrops,
} from '../callPlan/toFromDb';
import { localdate } from '@/common/models/types';
import { SaveResult, useCallFeedPlanDropsAndActuals } from './useCallFeedPlanDropsAndActuals';
import assertNever from 'assert-never';
import { RationToDryMatterRatio } from '@/common/models/ration';

// This is the structure returned by the useCallFeedPlan hook
// It combines both the plan and observations because these are saved together from the same screen
export type PlanAndObservations = {
  plan: PenCallPlan;
  observationsToday: PenObservations;
};

export type PenObservations = {
  bunkScore: BunkScoreValue | undefined;
  // agressionScore: number | undefined;
  // notes: string;
};

type CallFeedPlanData = {
  planYesterday: PenCallPlan;
  planAndObservations: PlanAndObservations;
  dropsMapYesterday: readonly CallFeedDropIdAndActual[];
  dropsMap: CallFeedDropsMap;
  numHead: number;
  savePlanAndObservations: (plan: PlanAndObservations) => Promise<SaveResult>;
};

// TODO: move to common area
/**
 * Returns a memoized object that only re-evaluates if any of its constituent property values change
 * and is undefined if any of its constituent property values are undefined
 *
 * So:
 * ```
 * const x = useObj({ red, green, blue });```
 * ```
 * is eqiuvalent to
 * ```
 * const x = useMemo(() => {
 *   if (red === undefined || green === undefined || blue === undefined) return undefined;
 *   return { red, green, blue };
 * }, [red, green, blue]);
 * ```
 **/
function useObj<T extends {}>(variables: T): { [K in keyof T]: Exclude<T[K], undefined> } | undefined {
  const vals = Object.values(variables);
  return useMemo(() => {
    if (vals.some((v) => v === undefined)) return undefined;
    return variables as { [K in keyof T]: Exclude<T[K], undefined> };
    // the following dependency array passes in the values of the props of variables in as the dependency array
    // which is exactly what we want here; we only want to re-evaluate if any of those values have changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, vals);
}

/** A feeding plan for a pen as read from the backend */
export function useCallFeedPlanData(
  penId: number,
  today: localdate,
  callMethod: PlanCallMethod,
  callUnit: PlanCallUnit,
  rationIdToDmRatio: RationToDryMatterRatio | undefined
): {
  loading: boolean;
  data: undefined | CallFeedPlanData;
  haveTomorrow: boolean;
  refetch: () => void;
} {
  // Get the "plan drops" which are the relevant fields from the drops from today and tomorrow
  const {
    loading,
    observationsToday,
    yesterdayPlanAndActuals,
    planDrops,
    dropsMap,
    numHead,
    savePlanDropsAndObservations,
    haveTomorrow,
    refetch,
  } = useCallFeedPlanDropsAndActuals(penId, today, callUnit);
  const plan = useMemo(() => (planDrops ? planDropsToPlan(planDrops, callMethod) : undefined), [planDrops, callMethod]);
  const planYesterday = useMemo(
    () =>
      yesterdayPlanAndActuals ? singleDayDropsToPlan(yesterdayPlanAndActuals.dbDrops, callMethod, callUnit) : undefined,
    [yesterdayPlanAndActuals, callMethod, callUnit]
  );
  const planAndObservations = useObj({ plan, observationsToday });
  const savePlanAndObservations = useMemo(() => {
    return dropsMap && rationIdToDmRatio
      ? async (planAndObservations: PlanAndObservations) =>
          await save(savePlanDropsAndObservations, planAndObservations, dropsMap, rationIdToDmRatio)
      : undefined;
  }, [savePlanDropsAndObservations, dropsMap, rationIdToDmRatio]);
  const state = useObj({
    planYesterday,
    planAndObservations,
    dropsMapYesterday: yesterdayPlanAndActuals?.dropsMap,
    dropsMap,
    numHead,
    savePlanAndObservations,
  });
  return { loading, data: state, haveTomorrow, refetch };
}

async function save(
  savePlanDropsAndObservations: (
    planDrops: PlanDbDrops,
    observations: PenObservations,
    plan: PenCallPlan
  ) => Promise<SaveResult>,
  planData: PlanAndObservations,
  dropsMap: CallFeedDropsMap,
  rationIdToDmRatio: RationToDryMatterRatio
) {
  const { plan, observationsToday } = planData;
  const planDrops = planToPlanDrops(plan, dropsMap, rationIdToDmRatio);
  return await savePlanDropsAndObservations(planDrops, observationsToday, plan);
}

function planToPlanDrops(
  plan: PenCallPlan,
  dropsMap: CallFeedDropsMap,
  rationIdToDmRatio: RationToDryMatterRatio
): PlanDbDrops {
  switch (plan.method) {
    case PlanCallMethod.ByDrop:
      return byDropPlanToDb(plan, dropsMap);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanToDb(plan, dropsMap, rationIdToDmRatio);
    default:
      assertNever(plan);
  }
}

function planDropsToPlan(planDrops: PlanDbDrops, callMethod: PlanCallMethod) {
  switch (callMethod) {
    case PlanCallMethod.ByDrop:
      return byDropPlanFromDb(planDrops);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanFromDb(planDrops);
    default:
      assertNever(callMethod);
  }
}

function singleDayDropsToPlan(singleDayDrops: PlanDayDbDrops, callMethod: PlanCallMethod, callUnit: PlanCallUnit) {
  const planDrops = {
    callUnit,
    today: singleDayDrops,
    tomorrow: [],
  };
  switch (callMethod) {
    case PlanCallMethod.ByDrop:
      return byDropPlanFromDb(planDrops);
    case PlanCallMethod.ByDistribution:
      return byDistributionPlanFromDb(planDrops);
    default:
      assertNever(callMethod);
  }
}
