import gql from 'graphql-tag';
import { localdate } from '@/common/models/types';
import {
  CallFeedPlanQuery,
  DropInput,
  PenDayDropsInput,
  PlanCallUnit,
  useCallFeedPlanQuery,
  useCallFeedSavePlanMutation,
} from '@/web-types';
import {
  DbOneTimeDrop,
  DbRecurringDrop,
  PlanDayDbDrops,
  PlanDbDrops,
  PlanDbDropsYesterday,
} from '../callPlan/toFromDb';
import { planUnitConvert } from '../callPlan/functions';
import { addDays } from '@/common/utils/date';
import { useCallback, useMemo } from 'react';
import { CallFeedDropsMap, CallFeedDropIdAndActual, PenCallPlan, CallFeedDropsMapYesterday } from '../callPlan';
import { isPresent } from 'ts-is-present';
import { usePolling } from '@/components/hooks/usePolling';
import { PenObservations } from './useCallFeedPlanData';
import { CombinedError } from 'urql';

const REFETCH_INTERVAL = 1000 * 30; // 30 seconds

gql`
  query CallFeedPlan($yesterday: LocalDate!, $tomorrow: LocalDate!, $penId: Int!) {
    getDrops(fromDate: $yesterday, includeBatchDrops: false, penIds: [$penId]) {
      id
      date
      calledLbs
      penDropIndex
      actualCalledLbs
      fedLbs
      fedOn
      planCallAmount
      planCallUnit
      planDayOverride
      rationId
      numHeads
      dryMatterRatio
    }
    getPen(id: $penId) {
      numHeads
      bunkScores {
        date
        bunkScore
      }
    }
    # Temporary kludge for detection if duplication of tomorrow would be necessary
    getPens {
      tomorrowDrops: drops(date: $tomorrow) {
        id
      }
    }
  }
  mutation CallFeedSavePlan($penId: Int!, $drops: [PenDayDropsInput!]!, $bunkScore: BunkScoreValue, $planJson: JSON!) {
    saveCallFeed(penId: $penId, drops: $drops, bunkScore: $bunkScore, planJson: $planJson) {
      id
      date
      calledLbs
      penDropIndex
      actualCalledLbs
      fedLbs
      fedOn
      planCallAmount
      planCallUnit
      planDayOverride
      rationId
      numHeads
      dryMatterRatio
    }
  }
`;

export type YesterdayPlanAndActuals = {
  dbDrops: PlanDayDbDrops;
  dropsMap: readonly CallFeedDropIdAndActual[];
};

// These are the drops that we load directly from the database
// They may be either one-time drops or recurring drops depending on which fields are populated
type UnconvertedDbDrop = CallFeedPlanQuery['getDrops'][number];

export type SaveResult = 'success' | { error: CombinedError };

/**
 * This converts from a list of drops as we receive them from the backend
 * into a structure of drops that can be consumed by the "toFromDb" functions
 * it takes care of separating recurring from one-time drops
 * and removing the nullability of some fields
 */
const toPlanDbDrops: (
  dbDrops: readonly UnconvertedDbDrop[],
  today: localdate,
  callUnit: PlanCallUnit
) => PlanDbDrops & PlanDbDropsYesterday = (dbDrops, today, callUnit) => {
  const { recurringYesterday, oneTimeYesterday, recurringToday, oneTimeToday, recurringTomorrow, remainder } = separate(
    dbDrops,
    today,
    callUnit
  );
  if (remainder.length) {
    // We have separated the drops above into categories
    // Every drop must go to one of these categories or something is wrong
    console.error('Uncategorized drops:', remainder);
    throw new Error('plan drops present that cannot be loaded into plan');
  }
  const drops: PlanDbDrops & PlanDbDropsYesterday = {
    callUnit,
    yesterday: {
      recurring: recurringYesterday,
      oneTime: oneTimeYesterday,
    },
    today: {
      recurring: recurringToday,
      oneTime: oneTimeToday,
    },
    tomorrow: recurringTomorrow,
  };
  return drops;
};

const toPenFeedDropsMap = (
  rawDrops: UnconvertedDbDrop[],
  today: localdate
): CallFeedDropsMap & CallFeedDropsMapYesterday => {
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const dropsYesterday = rawDrops.filter((d) => d.date === yesterday);
  const dropsToday = rawDrops.filter((d) => d.date === today);
  const dropsTomorrow = rawDrops.filter((d) => d.date === tomorrow);
  return {
    yesterday: dropsYesterday.map(dropToActual).filter(isPresent),
    today: dropsToday.map(dropToActual).filter(isPresent),
    tomorrow: dropsTomorrow.map(dropToActual).filter(isPresent),
  };
};

const separate = (dbDrops: readonly UnconvertedDbDrop[], today: localdate, callUnit: PlanCallUnit) => {
  type Recurring = NonNullable<ReturnType<typeof toRecurringDrop>>;
  type OneTime = NonNullable<ReturnType<typeof toOneTimeDrop>>;
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);
  const recurringYesterday: Recurring[] = [];
  const recurringToday: Recurring[] = [];
  const recurringTomorrow: Recurring[] = [];
  const oneTimeYesterday: OneTime[] = [];
  const oneTimeToday: OneTime[] = [];
  const remainder: UnconvertedDbDrop[] = [];
  for (const item of dbDrops) {
    const recurringDrop = toRecurringDrop(item, callUnit);
    if (recurringDrop !== undefined) {
      if (item.date === today) recurringToday.push(recurringDrop);
      else if (item.date === tomorrow) recurringTomorrow.push(recurringDrop);
      else if (item.date === yesterday) recurringYesterday.push(recurringDrop);
      else remainder.push(item);
      continue;
    }
    const oneTimeDrop = toOneTimeDrop(item);
    if (oneTimeDrop !== undefined) {
      if (item.date === today) oneTimeToday.push(oneTimeDrop);
      else if (item.date === yesterday) oneTimeYesterday.push(oneTimeDrop);
      else remainder.push(item);
      continue;
    }
    remainder.push(item);
  }
  return { recurringYesterday, oneTimeYesterday, recurringToday, oneTimeToday, recurringTomorrow, remainder } as const;
};

const toRecurringDrop = (drop: UnconvertedDbDrop, toCallUnit: PlanCallUnit): DbRecurringDrop | undefined => {
  if (drop.planCallAmount == null || drop.planCallUnit == null) return undefined;
  const { planCallAmount, planDayOverride, planCallUnit: fromCallUnit, numHeads, dryMatterRatio, rationId } = drop;
  return {
    id: drop.id,
    amount: planUnitConvert(planCallAmount, fromCallUnit, toCallUnit, numHeads ?? 0, dryMatterRatio ?? 1),
    override:
      planDayOverride == null
        ? null
        : planUnitConvert(planDayOverride, fromCallUnit, toCallUnit, numHeads ?? 0, dryMatterRatio ?? 1),
    rationId: drop.rationId,
  };
};

const toOneTimeDrop = (drop: UnconvertedDbDrop): DbOneTimeDrop | undefined => {
  if (drop.planCallAmount != null || drop.planCallUnit != null || drop.calledLbs == null) return undefined;
  return {
    id: drop.id,
    calledLbs: drop.calledLbs,
    rationId: drop.rationId,
  };
};

const recurringToDropInput = (callUnit: PlanCallUnit) => (d: DbRecurringDrop) => {
  const dropInput: DropInput = {
    id: d.id, // not used for recurring drops
    rationId: d.rationId,
    calledLbs: undefined, // not used for recurring drops
    planCallAmount: d.amount,
    planCallUnit: callUnit,
    planDayOverride: d.override,
  };
  return dropInput;
};

const oneTimeToDropInput = (d: DbOneTimeDrop): DropInput => {
  const dropInput: DropInput = {
    id: d.id,
    rationId: d.rationId,
    calledLbs: d.calledLbs,
    planCallAmount: undefined, // not used for one-time drops
    planCallUnit: undefined, // not used for one-time drops
    planDayOverride: undefined, // not used for one-time drops
  };
  return dropInput;
};

const dropToActual = (rawDrop: UnconvertedDbDrop): CallFeedDropIdAndActual => {
  const id = {
    dropId: rawDrop.id,
    planIndex: rawDrop.planCallUnit != null ? (rawDrop.penDropIndex ?? undefined) : undefined,
  };
  if (rawDrop.actualCalledLbs == null && rawDrop.fedOn == null) return id;
  return {
    ...id,
    actual: {
      rationId: rawDrop.rationId,
      head: rawDrop.numHeads ?? 0,
      afLbsCalled: rawDrop.actualCalledLbs!, // TODO: determine how to handle actuals with fed but no actualized call (batch feeders)
      afLbsFed: rawDrop.fedLbs,
    },
  };
};

export const useCallFeedPlanDropsAndActuals = (
  penId: number,
  today: localdate,
  callUnit: PlanCallUnit
): {
  loading: boolean;
  refetch: () => void;
  observationsToday: undefined | PenObservations;
  yesterdayPlanAndActuals: undefined | YesterdayPlanAndActuals;
  planDrops: undefined | PlanDbDrops;
  dropsMap: undefined | CallFeedDropsMap;
  numHead: undefined | number;
  haveTomorrow: boolean;
  savePlanDropsAndObservations: (
    planDrops: PlanDbDrops,
    observations: PenObservations,
    plan: PenCallPlan
  ) => Promise<SaveResult>;
} => {
  const yesterday = addDays(today, -1);
  const tomorrow = addDays(today, 1);

  // Raw query and mutations from/to GraphQL (drops)
  const [{ data, fetching, stale }, refetch] = useCallFeedPlanQuery({ variables: { yesterday, tomorrow, penId } });
  usePolling({ intervalMs: REFETCH_INTERVAL, enabled: true, refetches: [refetch] });
  const [{ fetching: saving, data: dataFromSave }, savePenDropsAndObservations] = useCallFeedSavePlanMutation();

  const haveTomorrow = data?.getPens?.some((pen) => (pen.tomorrowDrops?.length ?? 0) > 0) ?? false;

  // TODO: use return value of save
  const rawDrops = data?.getDrops;

  const planDrops = useMemo(
    () => (rawDrops ? toPlanDbDrops(rawDrops, today, callUnit) : undefined),
    [rawDrops, today, callUnit]
  );
  const dropsMap = useMemo(() => (rawDrops ? toPenFeedDropsMap(rawDrops, today) : undefined), [rawDrops, today]);

  const yesterdayPlanAndActuals =
    planDrops && dropsMap ? { dbDrops: planDrops.yesterday, dropsMap: dropsMap.yesterday } : undefined;

  const numHead = data?.getPen?.numHeads ?? undefined;

  const bunkScoreToday = data?.getPen?.bunkScores?.find((score) => score.date === today)?.bunkScore;
  const observationsToday = useMemo(() => ({ bunkScore: bunkScoreToday ?? undefined }), [bunkScoreToday]);
  const savePlanDropsAndObservations = useCallback(
    async (planDrops: PlanDbDrops, observations: PenObservations, plan: PenCallPlan) => {
      const recurringToday = planDrops.today.recurring.map(recurringToDropInput(planDrops.callUnit));
      const recurringTomorrow = planDrops.tomorrow.map(recurringToDropInput(planDrops.callUnit));
      const oneTime = planDrops.today.oneTime.map(oneTimeToDropInput);
      const todayDrops: PenDayDropsInput = {
        date: today,
        drops: [...recurringToday, ...oneTime],
      };
      const tomorrowDrops: PenDayDropsInput = {
        date: addDays(today, 1),
        drops: recurringTomorrow,
      };
      const result = await savePenDropsAndObservations(
        {
          penId,
          drops: [todayDrops, tomorrowDrops],
          bunkScore: observations.bunkScore ?? null,
          planJson: { plan, dropsMap },
        },
        { additionalTypenames: ['Drop'] }
      );
      // TODO: what to do with result.data?
      return result.error ? { error: result.error } : 'success';
    },
    [savePenDropsAndObservations, penId, today, dropsMap]
  );
  return {
    loading: fetching,
    observationsToday,
    yesterdayPlanAndActuals,
    planDrops,
    dropsMap,
    numHead,
    haveTomorrow,
    savePlanDropsAndObservations,
    refetch,
  };
};
