import { LoadingScreen } from '@/components/LoadingScreen';
import { ErrorScreen } from '@/components/ErrorScreen';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { PenDropDetailsTable } from '@/components/CallFeedTab/PenDropDetailsTable';
import Box from '@mui/material/Box';
import { PenHistoryGraph } from '@/components/CallFeedTab/PenHistoryGraph';
import { useGetPenDropDetailsDialogQuery } from '@/web-types';
import { DETAILS_QUERY_LIMIT } from '@/components/CallFeedTab/constants';
import { useMemo, useState } from 'react';
import { createMap } from '@/common/utils/map';

export const PenHistoryBox = ({ penId, lotId }: { penId: number; lotId: number }) => {
  const [graphShowDmi, setGraphShowDmi] = useState(false);
  const [{ fetching, data, error }] = useGetPenDropDetailsDialogQuery({
    variables: {
      penId,
      lotId,
      limit: DETAILS_QUERY_LIMIT,
    },
  });

  const graphData = useMemo(
    () => [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? -1 : 1)),
    [data?.getPenDropDetails]
  );
  const tableData = useMemo(() => {
    const items = [...(data?.getPenDropDetails?.penDropAtDates ?? [])].sort((a, b) => (a.date < b.date ? 1 : -1));
    items.length = Math.min(items.length, 10);
    return items;
  }, [data?.getPenDropDetails]);

  const feedHistoryRations = useMemo(() => {
    return data?.getPenDropDetails?.rations ?? [];
  }, [data?.getPenDropDetails]);

  const feedHistoryRationMap = useMemo(() => {
    return createMap(data?.getPenDropDetails?.rations ?? [], (r) => r.id);
  }, [data?.getPenDropDetails]);

  return (
    <>
      {fetching && <LoadingScreen />}
      {!fetching && error && <ErrorScreen />}
      {!fetching && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PenDropDetailsTable data={tableData} rations={feedHistoryRations} rationMap={feedHistoryRationMap} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" sx={{ minHeight: 300, height: { xs: 450, md: '100%' } }}>
              <Box flex={1}>
                <PenHistoryGraph penDropAtDates={graphData} rationMap={feedHistoryRationMap} showDmi={graphShowDmi} />
              </Box>
              <Box display="flex" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox checked={graphShowDmi} onChange={(e) => setGraphShowDmi(Boolean(e.target.checked))} />
                  }
                  label="DMI"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
