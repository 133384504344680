import Dialog from '@mui/material/Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import Box from '@mui/material/Box';
import { CallFeedPlan } from '@/components/CallFeed/CallFeedPlan';
import dayjs from 'dayjs';
import { LoadingScreen } from '@/components/LoadingScreen';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { PenFeed } from '@/components/CallFeedTab';
import { useMemo, useState } from 'react';
import { Subset } from '@/common/types/subset';
import { PenLot } from '@/web-types';
import { useRations } from '@/components/CallFeed/hooks/useRations';
import { useCallFeedPlanData } from '@/components/CallFeed/hooks/useCallFeedPlanData';
import { sumBy } from 'lodash';
import { Day } from './callPlan';

interface CallFeedDetailedModalProps {
  penId: number;
  pens: PenFeed[];
  isTodaySelected?: boolean;
  onExit?: (isTodaySelected: boolean) => void;
}

const today = dayjs.tz().format('YYYY-MM-DD');

export const showCallFeedDetailedModal = (props?: CallFeedDetailedModalProps) =>
  ModalManager.show(CallFeedDetailedModal, props);

const CallFeedDetailedModal = ModalManager.create<CallFeedDetailedModalProps>(
  ({ penId, pens, isTodaySelected, onExit }) => {
    const modal = useModal();
    const [_penId, _setPenId] = useState(penId);

    const [{ settings: orgSettings }] = useOrgSettings();
    const feedingMethod = orgSettings['feeding.feedingMethod'];
    const { method, unit } = orgSettings['feeding.primaryCallMethod'];

    const { rations, rationIdToDmRatio } = useRations();
    const { loading, data, haveTomorrow, refetch } = useCallFeedPlanData(
      _penId,
      today,
      method,
      unit,
      rationIdToDmRatio
    );
    const pen = pens.find((pen) => pen.id === _penId);
    const penLotsEndOfYesterday = (pen?.penLotsEndOfYesterday ?? []) as unknown as Subset<PenLot>[];
    const penLots = (pen?.penLots ?? []) as unknown as Subset<PenLot>[];
    const yesterdayNumHead = penLotsEndOfYesterday.reduce((acc, pl) => acc + (pl.numHeads ?? 0), 0);

    const increments = useMemo(
      () => ({
        dmiPerHead: orgSettings['feeding.dmiPerHeadIncrement'],
        afPerHead: orgSettings['feeding.afPerHeadIncrement'],
        af: orgSettings['feeding.afIncrement'],
      }),
      [orgSettings]
    );

    // we have to have the day selection remembered at this level so that if the user has to duplicate to tomorrow, the refresh doesn't reset them to today
    const [day, setDay] = useState<Day>(isTodaySelected ? 'today' : 'tomorrow');

    return (
      <Dialog fullScreen open={modal.visible} fullWidth>
        {loading || !data || !rations || !rationIdToDmRatio ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <LoadingScreen />
          </Box>
        ) : (
          <CallFeedPlan
            penId={_penId}
            yesterdayNumHead={yesterdayNumHead}
            currentHead={data.numHead}
            rations={rations}
            rationIdToDmRatio={rationIdToDmRatio}
            dropsMapYesterday={data.dropsMapYesterday}
            dropsMap={data.dropsMap}
            planYesterday={data.planYesterday}
            savedData={data.planAndObservations}
            saveData={data.savePlanAndObservations}
            feedingMethod={feedingMethod}
            increments={increments}
            onExit={(isTodaySelected) => {
              modal.remove();
              onExit?.(isTodaySelected);
            }}
            penLots={penLots}
            yesterdayWeightPerHeadLbs={getCurentWeightPerHeadLbs(penLotsEndOfYesterday)}
            currentWeightPerHeadLbs={getCurentWeightPerHeadLbs(penLots)}
            onChangePen={(penId) => _setPenId(penId)}
            haveTomorrow={haveTomorrow}
            day={day}
            setDay={setDay}
            refetch={refetch}
          />
        )}
      </Dialog>
    );
  }
);

const getCurentWeightPerHeadLbs = (penLots: Subset<PenLot>[]) => {
  // Calculate the average estimated weight across lots in th pen
  const penLotsWithWeight = penLots
    .map((penLot) => ({ weightLbs: penLot.lot?.projectedCurrentWeightPerHeadLbs ?? 0, numHeads: penLot.numHeads ?? 0 }))
    .filter((penLot) => penLot.numHeads > 0 && penLot.weightLbs > 0);

  return penLotsWithWeight.length === 0
    ? null
    : sumBy(penLotsWithWeight, (penLot) => penLot.numHeads * penLot.weightLbs) /
        sumBy(penLotsWithWeight, (penLot) => penLot.numHeads);
};
